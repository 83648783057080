import React, { Component } from "react";
import ReactDOM from "react-dom";
import { HashLink as Link } from "react-router-hash-link";
import Footer from "./Footer";

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import weltweit from "./img/numbers/weltweit.png";
import schweissgeraet from "./img/numbers/schweissgeraet.png";
import investition from "./img/numbers/investition.png";
import kreisdiagramm from "./img/numbers/kreisdiagramm.png";
import mitarbeiter from "./img/numbers/mitarbeiter.png";
//import {Link} from 'react-router-dom';

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formName: "",
      formOrg: "",
      formMail: "",
      formTel: "",
      formText: "",
      formNewsletter: true,
      sended: false,
      formPrivacy: false,
      sKlik: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {}

  handleChange(event) {
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState({
      [event.target.name]: value,
    });
    event.target.style.removeProperty("background-color");
    event.target.style.removeProperty("border-color");
  }

  handleSubmit(event) {
    event.preventDefault();
    if (!this.state.sended) {
      function validateEmail(email) {
        var re =
          /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      }

      function validateTel(tel) {
        var re = /^([\s]*[+]{1}[0-9?\s]{9,20}[\s]*)$/;
        return re.test(String(tel));
      }

      var errorCheck = true;

      if (
        this.state.formName === "" ||
        this.state.formOrg === "" ||
        this.state.formTel === "" ||
        this.state.formMail === "" ||
        this.state.formText === ""
      ) {
        alert("Pro odeslání prosím vyplňte všechna pole!");
        errorCheck = false;
        ReactDOM.findDOMNode(this.refs.parallaxSmoke);

        if (this.state.formPrivacy === false) {
          ReactDOM.findDOMNode(this.refs.privacy).style.backgroundColor =
            "#ffe5e3";
          ReactDOM.findDOMNode(this.refs.privacy).style.borderColor = "red";
        }

        if (this.state.formName === "") {
          ReactDOM.findDOMNode(this.refs.name).style.backgroundColor =
            "#ffe5e3";
          ReactDOM.findDOMNode(this.refs.name).style.borderColor = "red";
        }

        if (this.state.formOrg === "") {
          ReactDOM.findDOMNode(this.refs.org).style.backgroundColor = "#ffe5e3";
          ReactDOM.findDOMNode(this.refs.org).style.borderColor = "red";
        }

        if (this.state.formTel === "") {
          ReactDOM.findDOMNode(this.refs.tel).style.backgroundColor = "#ffe5e3";
          ReactDOM.findDOMNode(this.refs.tel).style.borderColor = "red";
        }

        if (this.state.formMail === "") {
          ReactDOM.findDOMNode(this.refs.mail).style.backgroundColor =
            "#ffe5e3";
          ReactDOM.findDOMNode(this.refs.mail).style.borderColor = "red";
        }

        if (this.state.formText === "") {
          ReactDOM.findDOMNode(this.refs.text).style.backgroundColor =
            "#ffe5e3";
          ReactDOM.findDOMNode(this.refs.text).style.borderColor = "red";
        }
      }

      if (this.state.formPrivacy === false) {
        alert("Pro odeslání musíte souhlasit se zpracovnáním osobních údajů!");
        ReactDOM.findDOMNode(this.refs.privacy).style.backgroundColor =
          "#ffe5e3";
        ReactDOM.findDOMNode(this.refs.privacy).style.borderColor = "red";
        errorCheck = false;
      }

      if (!validateEmail(this.state.formMail) && errorCheck) {
        alert("Pro odeslání prosím vyplňte validní email!");
        ReactDOM.findDOMNode(this.refs.mail).style.backgroundColor = "#ffe5e3";
        ReactDOM.findDOMNode(this.refs.mail).style.borderColor = "red";
        errorCheck = false;
      }

      if (!validateTel(this.state.formTel) && errorCheck) {
        alert(
          "Pro odeslání prosím vyplňte telefon ve formátu +yyy xxx xxx xxx"
        );
        ReactDOM.findDOMNode(this.refs.tel).style.backgroundColor = "#ffe5e3";
        ReactDOM.findDOMNode(this.refs.tel).style.borderColor = "red";
        errorCheck = false;
      }

      if (errorCheck) {
        //eactDOM.findDOMNode(this.refs.submit).className = "submiting";
        ReactDOM.findDOMNode(this.refs.submit).innerHTML =
          '<i class="fas fa-spinner spin"></i> Odesílám';

        window.gtag("event", "conversion", {
          send_to: "AW-760677418/RjaHCNWc3ZUBEKqI3OoC",
        });
        this.setState({ sKlik: true });

        fetch("/api/contact", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            formName: this.state.formName,
            formOrg: this.state.formOrg,
            formMail: this.state.formMail,
            formTel: this.state.formTel,
            formText: this.state.formText,
            formNewsletter: this.state.formNewsletter,
          }),
        })
          .then((res) => {
            if (res.ok) {
              return res.json;
            } else {
              ReactDOM.findDOMNode(this.refs.submit).className = "submitError";
              ReactDOM.findDOMNode(this.refs.submit).innerHTML =
                '<i class="fas fa-exclamation-triangle"></i> Error';
            }
          })
          .then((json) => {
            if (json) {
              ReactDOM.findDOMNode(this.refs.submit).className = "submitOk";
              ReactDOM.findDOMNode(this.refs.submit).innerHTML =
                '<i class="fas fa-check"></i> Odesláno';
              this.setState({ sended: true });
            } else {
              ReactDOM.findDOMNode(this.refs.submit).className = "submitError";
              ReactDOM.findDOMNode(this.refs.submit).innerHTML =
                '<i class="fas fa-exclamation-triangle"></i> Error';
            }
          });
      }
    } else {
      alert("Již odesláno!");
    }
  }
  render() {
    const MyMapComponentBenesov = withScriptjs(
      withGoogleMap((props) => (
        <GoogleMap
          defaultZoom={17}
          defaultCenter={{ lat: 49.78089, lng: 14.684562 }}
        >
          {props.isMarkerShown && (
            <Marker position={{ lat: 49.78089, lng: 14.684562 }} />
          )}
        </GoogleMap>
      ))
    );

    const MyMapComponentOstrava = withScriptjs(
      withGoogleMap((props) => (
        <GoogleMap
          defaultZoom={17}
          defaultCenter={{ lat: 49.82620910971927, lng: 18.252918294508785 }}
        >
          {props.isMarkerShown && (
            <Marker
              position={{ lat: 49.82620910971927, lng: 18.252918294508785 }}
            />
          )}
        </GoogleMap>
      ))
    );
    return (
      <div className="contentMargin">
        <div className="section">
          <div className="contentSize">
            <div className="content">
              <h2>Rodinná tradice pojící kov a kvalitu</h2>
              <p>
                Zakládáme si na dobrých vztazích a kvalitě. EWM založil Edmund
                Szczesny v poválečném Německu v roce 1957. Ze začátku jsme
                vyráběli elektronické součástky pro jiné výrobce svařovacích
                přístrojů. Postupně jsem začali specializovat své vlastní
                svářečky osazené moderními technologiemi.
              </p>
              <h3>Rodinná tradice</h3>
              <p>
                EWM je až do dnešních dní rodinný podnik. Jsme rodina svářečů,
                která vždy dbala na to, aby svým kolegům svářečům přinášela jen
                tu nejvyšší kvalitu a pohodlí pro dosažení nejvyšších výsledků.
              </p>
              <h3>Technologie</h3>
              <p>
                EWM znamená také moderní technologie. Jsme držiteli řady
                důležitých patentů. Nejdůležitějším je bezesporu vynález
                svařování hliníku metodou TIG pomocí AC/DC invertoru. Psal se
                rok 1988.
              </p>
              <h3>Současnost</h3>
              <p>
                EWM je o kvalitě. Proto jsme v roce 1994 otevřeli výrobní podnik
                v Jiříkově na severu Čech. Jen tak jsme dokázali udržet vysoký
                standard, ačkoli trend jsem spíše opačným směrem. Naše obchodní
                oddělení najdete v Benešově u Prahy a Ostravě.
              </p>
            </div>
          </div>
        </div>

        <div className="Line">
          <div className="LineBg2" style={this.state.mobileSafari}></div>
        </div>

        <div className="section inNumbers fiveProducts">
          <div className="contentSize">
            <div className="contentHeading">
              <h3>EWM v číslech</h3>
            </div>

            <div className="content">
              <h2>61</h2>
              <div className="productImage">
                <img src={weltweit} alt="" />
              </div>
              <p>let tradice</p>
            </div>
            <div className="content">
              <h2>400</h2>
              <div className="productImage">
                <img src={schweissgeraet} alt="" />
              </div>
              <p>prodejních a servisních míst po celém světě</p>
            </div>
            <div className="content">
              <h2>60 000+</h2>
              <div className="productImage">
                <img src={investition} alt="" />
              </div>
              <p>vyrobených svářecích zdrojů a komponent ročně</p>
            </div>
            <div className="content">
              <h2>10%</h2>
              <div className="productImage">
                <img src={kreisdiagramm} alt="" />
              </div>
              <p>investováno do inovac</p>
            </div>
            <div className="content">
              <h2>600+</h2>
              <div className="productImage">
                <img src={mitarbeiter} alt="" />
              </div>
              <p>zaměstnanců EWM Group</p>
            </div>
          </div>
          <div id="contact" />
        </div>
        {/*<div className="section bg1">
          <div className="contentSize">
            <div className="content">
               <div className="contactForm">
                <h2>Napište nám</h2>
                <form onSubmit={this.handleSubmit}>
                  <div className="contactRow">
                    <div className="contactForm50">
                      <label>Jméno</label>
                      <i className="fas fa-user inputIcon"></i>
                      <input
                        type="text"
                        value={this.state.formName}
                        name="formName"
                        onChange={this.handleChange}
                        ref="name"
                      ></input>
                    </div>

                    <div className="contactForm50">
                      <label>Organizace</label>
                      <i className="fas fa-building inputIcon"></i>
                      <input
                        type="text"
                        value={this.state.formOrg}
                        name="formOrg"
                        onChange={this.handleChange}
                        ref="org"
                      ></input>
                    </div>
                  </div>

                  <div className="contactRow">
                    <div className="contactForm50">
                      <label>E-mail</label>
                      <i className="fas fa-envelope inputIcon"></i>
                      <input
                        type="text"
                        value={this.state.formMail}
                        name="formMail"
                        onChange={this.handleChange}
                        ref="mail"
                      ></input>
                    </div>

                    <div className="contactForm50">
                      <label>Telefon</label>
                      <i className="fas fa-phone inputIcon"></i>
                      <input
                        type="text"
                        value={this.state.formTel}
                        name="formTel"
                        onChange={this.handleChange}
                        ref="tel"
                      ></input>
                    </div>
                  </div>

                  <div className="clear"></div>
                  <div className="contactForm100">
                    <label>Text</label>
                    <textarea
                      value={this.state.formText}
                      name="formText"
                      onChange={this.handleChange}
                      ref="text"
                    />
                  </div>
                  <div className="contactForm100">
                    <input
                      type="checkbox"
                      className="privacyCheckbox"
                      value={this.state.privacy}
                      name="formPrivacy"
                      onChange={this.handleChange}
                      ref="privacy"
                    />
                    <Link to="/privacy">
                      Seznámil jsem se se zásadami zpracování osobních údajů
                    </Link>
                  </div>
                  <div className="contactForm100 newsletterCheckbox">
                    <input
                      type="checkbox"
                      className="privacyCheckbox"
                      checked={this.state.formNewsletter}
                      name="formNewsletter"
                      onChange={this.handleChange}
                    />
                    <span>Souhlasím se zasíláním obchodních sdělení</span>
                  </div>
                  <button type="submit" ref="submit" className="submitWait">
                    <i className="fas fa-share-square" ref="buttonIco"></i>
                    Odeslat
                  </button>
                </form>
              </div> 
            </div>
          </div>
        </div> */}
        <div className="contactAddress">
          <div className="contentSize">
            <h2 className="footerH">Kontakt</h2>
            <div className="footerAddress">
              <strong>Benešov</strong>
              <br />
              Tyršova 2106
              <br />
              265 01
              <br />
              sales@ewm.cz
              <br />
              Tel. +420 317 729 491
            </div>
            <div className="footerMap mapMobileBenesov">
              <MyMapComponentBenesov
                isMarkerShown
                googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyCk16CnO0Iddc5jxFxqGRR4ZCa72_zcMHY&libraries=geometry,drawing,places"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `200px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
              />
            </div>
            <div className="footerAddress">
              <strong>Ostrava</strong>
              <br />
              Tovární 11
              <br />
              709 00
              <br />
              ostrava@ewm-group.com
              <br />
              Tel. +420 725 712 483
            </div>
            <div className="clear"></div>

            <div className="footerMap mapDesktopBenesov">
              <MyMapComponentBenesov
                isMarkerShown
                googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyCk16CnO0Iddc5jxFxqGRR4ZCa72_zcMHY&libraries=geometry,drawing,places"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `200px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
              />
            </div>
            <div className="footerMap">
              <MyMapComponentOstrava
                isMarkerShown
                googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyCk16CnO0Iddc5jxFxqGRR4ZCa72_zcMHY&libraries=geometry,drawing,places"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `200px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
              />
            </div>
          </div>
        </div>
        <Footer />
        {this.setState.sKlik ? (
          <iframe
            width={119}
            height={22}
            frameborder={0}
            scrolling="no"
            src="//c.imedia.cz/checkConversion?c=100050440&color=ffffff&v="
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default Contact;
