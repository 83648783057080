import React, { Component } from "react";
import YouTube from "react-youtube";
import Slider from "react-slick";
import Footer from "./Footer";
import NewsItems from "./NewsItems";
import ReferenceSlider from "./ReferenceSlider";
import { HashLink as Link } from "react-router-hash-link";
import Mma from "./img/photos/mma.png";
import Migmag from "./img/index/MigMag.jpg";
import Wig from "./img/photos/wig.png";
import Plasma from "./img/photos/plasma.png";
import EwmLogo from "./img/ewmblogo.png";
import IntroViedo from "./video/intro.mp4";
import RobotViedo from "./video/robot.mp4";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileSafari: {},
      YtData: {
        items: [],
      },
    };
  }

  componentDidMount() {
    fetch(
      "https://www.googleapis.com/youtube/v3/playlistItems?part=snippet%2CcontentDetails&maxResults=6&playlistId=PLZU-EOGC1MbOinYfVNv7qXO9GzWSI2IYO&key=AIzaSyADO6BXZB1UpkoIJVp_Xx8oEiZj9ZZEY10"
    )
      .then((res) => res.json())
      .then((json) => {
        this.setState({ YtData: json });
        //console.log(json);
      });

    var ua = window.navigator.userAgent;
    var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    var webkit = !!ua.match(/WebKit/i);
    var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

    if (iOSSafari) {
      this.setState({
        mobileSafari: {
          "background-attachment": "scroll",
          position: "fixed",
        },
      });
    }
  }

  render() {
    const opts = {
      height: "100%",
      width: "100%",
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 0,
        controls: 0,
        showinfo: 0,
        modestbranding: 0,
      },
    };

    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
    };

    const listYoutube = this.state.YtData.items.map((item, index) => (
      <div className="ytV" key={index}>
        <YouTube
          videoId={item.snippet.resourceId.videoId}
          opts={opts}
          onReady={this._onReady}
          onPlay={this._onPlay}
          onPause={this._onStop}
          onEnd={this._onStop}
        />
      </div>
    ));

    return (
      <div className="contentMargin">
        <div className="section" id="wearewelding">
          <div className="contentSize">
            <div className="content">
              <h2>Svářečky mistrů řemesla</h2>
              <img src={EwmLogo} alt="EWM" className="logoEwmIndex" />
              <p>
                MMA, MIG a WIG svářecí stroje navržené samotnými svářeči.
                Intuitivní ovládání. Využívají moderní technologie. S životností
                podle nejvyšších německých standardů.
              </p>
            </div>
          </div>
        </div>

        <div className="section intro">
          <video loop muted autoPlay playsInline className="introVideo">
            <source src={IntroViedo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        {/*<div className="introImg"></div>*/}

        <div className="productTable" id="home">
          <Link className="product" to="/mma">
            <h3>MMA</h3>

            <p>Svařování obalenou elektrodou v terénu i v dílně.</p>
            <div className="indexCatImg mma"></div>
          </Link>
          <Link className="product" to="/migmag">
            <h3>MIG/MAG</h3>

            <p>
              Invertorové stroje se synergickými procesy. Zkratový a pulzní
              přenos.
            </p>
            <div className="indexCatImg migmag"></div>
          </Link>
        </div>
        <div className="productTable" id="home">
          <Link className="product" to="/tig">
            <h3>TIG/WIG</h3>

            <p>
              Varianty DC a AC⚡DC včetně možnosti automatického podávání drátu
            </p>
            <div className="indexCatImg tig"></div>
          </Link>
          <Link className="product" to="/plasma">
            <h3>Plazmové svařování</h3>

            <p>Precizní svařovaní plasmovým obloukem.</p>
            <div className="indexCatImg plasma"></div>
          </Link>
        </div>

        <div className="section intro">
          <video loop muted autoPlay playsInline className="introVideo">
            <source src={RobotViedo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <Link className="section noUnderline" to="/doosan_robotics">
          <div className="contentSize">
            <div className="content noBottomPadding">
              <h2>Doosan Robotics</h2>
              <p>
                <strong>Dokonalejší než Vaše představy</strong>
              </p>
              <p>
                Seznamte se s našími kolaborativními roboty. Zažijte jejich
                delikátní a vininkající výkon jen jedním dotekem.
              </p>
            </div>
          </div>
        </Link>
        <Link
          className="productTable noBottomPadding noUnderline"
          to="/doosan_robotics"
        >
          <div
            className="product noBottomPadding noBottomMargin"
            to="/doosan_robotics"
          >
            <div className="indexCatImg2 robot"></div>
          </div>
          <div className="product" to="/">
            <ul className="subcategoryList">
              <li>
                Nabízíme Vám colaborativní roboty Doosan s nejširší oblasti
                využití s nosností od 6 do 15 kg a pracovním rádiusem od 600 do
                1700 mm.{" "}
              </li>
              <li>
                Pomůžeme Vám vybrat nejoptimálnější variantu pro vaše využití
                včetně svařování.
              </li>
            </ul>
          </div>
        </Link>
        {/* <div className="LineSmall">
          <div className="LineBgOrbit" style={this.state.mobileSafari}></div>
        </div> */}

        <div className="section soldamatic">
          <div className="contentSize">
            <div className="content">
              <h2>Učíme svařovat. V rozšířené realitě.</h2>
              <p>
                Představujeme Soldamatic svářecí simulátor, které šetří náklady
                a čas potřebný pro zaškolení nových svářečů.
              </p>

              <Link className="linkRect" to="/soldamatic">
                <span>Více o Soldamaticu</span>
              </Link>
            </div>
          </div>
        </div>
        <div
          className="soldamaticImg pointer"
          onClick={() => this.props.history.push("/soldamatic")}
        ></div>
        <div className="section ">
          <div className="contentSize">
            <div className="content noBottomPadding">
              <h2>Orbital weldingtools</h2>
              <p>
                Orbitální svařovací proces je mechanizovaný svařovací proces,
                při kterém je hořák během svařování mechanicky veden kolem pevné
                svařovací části. Tato metoda se používá hlavně při stavbě
                potrubí, kde musí být za kontrolovatelných podmínek splněny
                vysoké kvality svárů a v některých případech i speciální
                hygienické požadavky.
              </p>
              <p>
                <strong>
                  Technologie zařízení, kterou nabízíme, se již úspěšně používá
                  v těchto průmyslových odvětvích:
                </strong>
              </p>
            </div>
          </div>
        </div>
        <div className="productTable">
          <Link className="product" to="/">
            <ul className="subcategoryList">
              <li>Potraviny</li>
              <li>Nápoje</li>
              <li>Lékárnictví</li>
              <li>Biotech</li>
              <li>Polovodiče</li>
              <li>Letectví / kosmické lety</li>
              <li>Obnovitelné energie</li>
            </ul>
          </Link>
          <Link className="product noBottomPadding " to="/">
            <div className="indexCatImg2 orbit cover"></div>
          </Link>
        </div>
        <div className="section newsIndex">
          <div className="content ">
            <h2 className="noBottomMargin">Reference</h2>
          </div>
          <ReferenceSlider />
        </div>
        <div className="section newsIndex noBottomPadding">
          <div className="contentSize">
            <div className="content">
              <h2>Inspirujte se od mistrů</h2>
              <div className="newsIndexWrapper">
                <NewsItems from={0} to={5} />
                <Link className="linkRect" to="/news">
                  <span>Podívejte se na další články</span>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default Home;
