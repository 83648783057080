import React, { Component } from "react";
// import './comp-css/main.css';

//import "bootstrap/dist/css/bootstrap.min.css";
import "./style/main.scss";
import ReactDOM from "react-dom";
import { Link, withRouter } from "react-router-dom";
import ReactTimeout from "react-timeout";
import Main from "./Main";
import Menu from "./Menu";
import mImg from "./img/small/smallest/m.png";
import logoImg from "./img/logo.png";
import logoImgInv from "./img/logo_inv.png";
import fgImg from "./img/small/fg.png";
import bgImg from "./img/bg.jpg";
import mImgSmall from "./img/small/m.png";
// import mImgBig from './img/m.png';
// import pImgBig from './img/p.png';

class App extends Component {
  constructor(props) {
    super(props);
    this.logoRef = this.logoRef.bind(this);
    this.state = {
      imgLoaded: 0,
      imgLoading: true,
      setStMenu: false,
      setLogo: false,
      data: {
        menu: [
          { item: "prvni", slug: "prvnislug" },
          { item: "dr", slug: "drs" },
        ],
      },
    };
  }

  getOffset(element) {
    var bounding = element.getBoundingClientRect();
    return {
      top: bounding.top + document.body.scrollTop,
      left: bounding.left + document.body.scrollLeft,
    };
  }

  componentDidUpdate(prevProps) {
    const gtag = window.gtag;

    if (prevProps.location.pathname !== this.props.location.pathname) {
      if (this.props.history.action === "PUSH" && typeof gtag === "function") {
        gtag("config", "GA_TRACKING_ID", {
          page_location: window.location.href,
          page_path: this.props.location.pathname,
        });
      }
    }

    if (this.state.imgLoaded === 3 && this.state.imgLoading) {
      this.setState(
        {
          stBlackOver: {
            opacity: "0",
          },
          imgLoading: false,
        },
        () => {
          ReactDOM.findDOMNode(this.refs.bg).style.opacity = 1;
          ReactDOM.findDOMNode(this.refs.fg).style.opacity = 1;
        }
      );
      // var SmallImgM = new Image();
      // SmallImgM.src = mImgSmall;
      // SmallImgM.onload = () => {
      //   ReactDOM.findDOMNode(this.refs.manImg).style.backgroundImage = `url(${mImgSmall})`;
      //   this.setState({imgLoading: false});
      //
      //   var BigImgM = new Image();
      //   BigImgM.src = mImgBig;
      //   BigImgM.onload = () => {
      //
      //     ReactDOM.findDOMNode(this.refs.manImg).style.backgroundImage = `url(${mImgBig})`;
      //     ReactDOM.findDOMNode(this.refs.manImg).style.opacity = "1";
      //
      //
      //     this.props.setTimeout(() => {
      //       ReactDOM.findDOMNode(this.refs.manImgSmall).style.display = "none";
      //     }, 5000);
      //
      //     this.props.setTimeout(() => {
      //       ReactDOM.findDOMNode(this.refs.manImgSmall).style.opacity = "0";
      //     }, 1000);
      //
      //   };
      // };

      // var BigImgP = new Image();
      // BigImgP.src = pImgBig;
      // BigImgP.onload = () => {
      //   ReactDOM.findDOMNode(this.refs.parallaxFlash).style.backgroundImage = `url(${pImgBig})`;
      // };
    }
  }

  componentDidMount() {
    var element = ReactDOM.findDOMNode(this.refs.parallaxSmoke);
    var rect = element.getBoundingClientRect();
    var elementNavbar = ReactDOM.findDOMNode(this.refs.navbar);
    var rectNavbar = elementNavbar.getBoundingClientRect();
    var resChange = this.props.resChange;
    resChange(rect.top + rect.height - rectNavbar.height);

    //this.setState({'parallaxScroll': ReactDOM.findDOMNode(this.refs.parallaxScroll)});

    //this.setState({'rectLogo': rectLogo, 'rectTreti': rectTreti, 'logoChangeScroll': logoChangeScroll});
    //window.addEventListener('scroll', this.handleScroll.bind(this));

    var imgLogo = new Image();
    imgLogo.src = logoImg;

    var imgLogoInv = new Image();
    imgLogoInv.src = logoImgInv;

    var imgM = new Image();
    imgM.src = mImg;
    imgM.onload = () => {
      this.setState(
        {
          imgLoaded: this.state.imgLoaded + 1,
        },
        () => {
          ReactDOM.findDOMNode(this.refs.m).style.opacity = 1;
        }
      );
    };

    var imgF = new Image();
    imgF.src = fgImg;
    imgF.onload = () => {
      this.setState({
        imgLoaded: this.state.imgLoaded + 1,
      });
    };

    var imgBg = new Image();
    imgBg.src = bgImg;
    imgBg.onload = () => {
      this.setState({
        imgLoaded: this.state.imgLoaded + 1,
      });
    };

    //ReactDOM.findDOMNode(this.refs.parallaxScroll).addEventListener('scroll', this.handleScroll.bind(this));
    window.addEventListener("scroll", this.handleScroll.bind(this));

    function getRndInteger(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    let parallaxFlash = ReactDOM.findDOMNode(this.refs.parallaxFlash);

    // window.setInterval(function() {
    //   parallaxFlash.style.opacity = getRndInteger(1,10) / 10;
    //   parallaxFlash.style.filter  = 'alpha(opacity=90)';
    // }, 100);
  }

  componentWillUnmount() {
    //  window.removeEventListener('scroll', _.throttle(this.handleScroll.bind(this), 30));
  }

  logoRef(input) {
    this.childInput = input;
  }

  handleScroll() {
    //console.log(window.pageYOffset);
    ReactDOM.findDOMNode(this.refs.parallaxScroll).scrollTop =
      window.pageYOffset;
    var elementTreti = ReactDOM.findDOMNode(this.refs.parallaxSmoke);
    var elementLogo = ReactDOM.findDOMNode(this.refs.parallaxLogo);
    var navbar = ReactDOM.findDOMNode(this.refs.navbar);
    //var elementMenu = ReactDOM.findDOMNode(this.refs.menu);
    //var elementLogo = ReactDOM.findDOMNode(this.refs.parallaxLogo);
    //var elementHeader= ReactDOM.findDOMNode(this.refs.header);
    //var rectMenu = elementMenu.getBoundingClientRect();

    var rectTreti = elementTreti.getBoundingClientRect();
    var rectNavbar = navbar.getBoundingClientRect();
    var rectLogo = elementLogo.getBoundingClientRect();
    var logoChangeScroll = rectTreti.height / 2 + 50 - rectLogo.height / 2;
    var windowsScrollTop = ReactDOM.findDOMNode(
      this.refs.parallaxScroll
    ).scrollTop;

    //var header = elementHeader.getBoundingClientRect();

    if (windowsScrollTop > rectTreti.height - rectNavbar.height) {
      ReactDOM.findDOMNode(this.refs.navbar).style.position = "fixed";
      ReactDOM.findDOMNode(this.refs.navbar).style.top = "0px";
    } else {
      ReactDOM.findDOMNode(this.refs.navbar).style.position = "absolute";
    }

    if (windowsScrollTop > logoChangeScroll) {
      ReactDOM.findDOMNode(this.refs.parallaxLogo).style.top = "-50px";
      ReactDOM.findDOMNode(this.childInput).style.left = "60px";
      /*    if(this.state.setLogo !== true) {
      this.setState({

        'stMenuImg': {
          left: "60px"
        },
        'stHeaderLogo': {
          top: "-50px"
        },
        'setLogo': true
      });
    } */
    } else {
      ReactDOM.findDOMNode(this.refs.parallaxLogo).style.removeProperty("top");
      ReactDOM.findDOMNode(this.childInput).style.removeProperty("left");
      //if(this.state.setLogo !== false) {
      //this.setState({'stMenuImg': {}, 'stHeaderLogo': {}, 'setLogo': false});
      //}
    }

    //var topPos1 = (rectTreti.top + (windowsScrollTop * 1.5));
    //var topPos2 = (rectTreti.top + (windowsScrollTop * 1.3));
    //var topPos3 = (rectTreti.top + (windowsScrollTop * 0.3));
    //var topPos4 = (rectLogo.top + (windowsScrollTop / 0.643)); / 0.65
    //var n = ((rectMenu.height / (rectLogo.height / 100)) - 1);
    /*
    this.setState({
      'st2': {
        top: rectTreti.top + (windowsScrollTop * 1.3) + "px"
      }
    });

  if (topPos4 <= windowsScrollTop) {
      var siz = rectMenu.height - 2;
      this.setState({
        'stLogo': {
          position: "fixed",
          top: "1px",
          height: siz + "px"
        },
        'stLogoMenu': {
          position: "fixed",
          top: "1px",
          height: siz + "px"
        }
      });

    } else {

      var y = rectLogo.top + windowsScrollTop;
      var perc = (topPos4 - windowsScrollTop) / y;
      perc = (((100 - n) / 100) * perc) + (n / 100);

      var logoHeight = rectLogo.height * perc;

      this.setState({
        'stLogo': {
          top: topPos4 + "px",
          height: logoHeight + "px"
        },
        'stLogoMenu': {
          top: topPos4 - header.height  + "px",
          height: logoHeight + "px"
        }
      });

    }
    /*

    this.setState({
      'st1': {
        top: topPos1 + "px"
      }
    });

    this.setState({
      'st3': {
        top: topPos3 + "px"
      }
    });
*/
  }

  render() {
    return (
      <div className="App">
        {/*<div className="parallaxOver"></div>*/}

        <div className="parallaxWrapper">
          <div className="parallax" ref="parallaxScroll">
            <div className="parallax-logo-img-inv" ref="parallaxLogo"></div>
            <div className="blackOver" style={this.state.stBlackOver}></div>
            <div id="group4" className="parallax__group" ref="parallaxSmoke">
              <div className="parallax__layer parallax__layer--base">
                <div className="title fg" ref="fg"></div>
              </div>
              <div className="parallax__layer parallax__layer--back">
                <div className="title m" ref="m">
                  <div
                    className="title mlowres"
                    id="smoke"
                    ref="manImgSmall"
                  ></div>
                  <div className="title mhires" id="smoke" ref="manImg"></div>
                  <div className="title p" id="smoke" ref="parallaxFlash"></div>
                </div>
              </div>
              <div className="parallax__layer parallax__layer--deep">
                <div className="title bg" ref="bg"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="contentWrapper">
          <Menu
            className="menuW"
            logoRef={this.logoRef}
            ref="navbar"
            resChange={this.props.resChange}
            store={this.props.store}
          />

          <Main
            resChange={this.props.resChange}
            store={this.props.store}
            refParalax={this.state.parallaxScroll}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(ReactTimeout(App));
