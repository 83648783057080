import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Settings from './lib/settings';
import dateFormat from 'dateformat';

class NewsItemsFull extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: {
        news: []
      }
    };
  }

  componentDidMount() {
    fetch('/api/news', Settings.fetchParams('GET')).then(res => res.json()).then(json => {
      if (json !== null) {
        this.setState({
          data: {
            news: json.news
          }
        });
      }
    });
  }

  render() {


    const listItems = this.state.data.news.slice(this.props.from, this.props.to).map((item, index) => {

      let linkToArticle = null;
      if(item.slug !== null) {
        linkToArticle = <Link to={"/article/" + item.slug} className="newsLink">Celý článek</Link>;
      }


    return (
      <div className="newsBig">
        <div className="newsTextWrapperBig">
          <h3 className="newsBigH3">{item.title}</h3>
          <div className="newsImageBig" style={{backgroundImage: "url(./img/news/" + item.image + ")"}}></div>
          <p className="newsDate">{dateFormat(item.date, "d.m. yyyy")}</p>

          <p className="newsText">{item.short_text}</p>
          {linkToArticle}
        </div>
      </div>)}
  );

    return (<div className="news">

      {listItems}
      <div className="clear"></div>
    </div>);
  }

}

export default NewsItemsFull;
