import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import { BrowserRouter } from "react-router-dom";
import { createStore } from "redux";
import reportWebVitals from "./reportWebVitals";

var initialState = 0;

function pSize(state = initialState, action) {
  switch (action.type) {
    case "CHANGE":
      return action.text;

    default:
      return state;
  }
}

var store = createStore(pSize);

var resChange = function (text) {
  store.dispatch({ type: "CHANGE", text: text });
};

ReactDOM.render(
  <BrowserRouter>
    <App resChange={resChange} store={store} />
  </BrowserRouter>,
  document.getElementById("root")
);
reportWebVitals();
