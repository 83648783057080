import React, {Component} from 'react';
import NewsItems from './NewsItems';
import NewsItemsFull from './NewsItemsFull';
import Footer from './Footer';

  class News extends Component {





    render() {

      return (

        <div className="contentMargin">

          <div className="section bg3">
            <div className="contentSize">
              <div className="content">
                <NewsItemsFull from={0} to={1} />
                <NewsItems from={1} to={6} newsColor={true} />
              </div>
            </div>
          </div>

          <Footer />

        </div>
      );
    }

  }

  export default News;
