import React, {Component} from 'react';
import YouTube from 'react-youtube';
import Footer from './Footer';
import Settings from './lib/settings';

  class Videos extends Component {

    constructor(props) {
      super(props);
      this.state = {
        YtData: {
          items: []
        }
      };
    }




    componentDidMount() {

      fetch('/api/videos', Settings.fetchParams('GET')).then(res => res.json()).then(json => {
        if (json !== null) {

          let videoIds = [];
          json.videos.forEach((item, index) => {
            videoIds.push(item.slug);
          });
          let urlIds = encodeURI(videoIds.toString());
          fetch('https://www.googleapis.com/youtube/v3/videos/?part=snippet&id=' + urlIds + '&key=AIzaSyADO6BXZB1UpkoIJVp_Xx8oEiZj9ZZEY10').then(res => res.json()).then(json => {
            this.setState({YtData: json});
            //console.log(json);
          });
        }
      });
      // fetch('https://www.googleapis.com/youtube/v3/playlistItems?part=snippet%2CcontentDetails&maxResults=6&playlistId=PLZU-EOGC1MbOinYfVNv7qXO9GzWSI2IYO&key=AIzaSyADO6BXZB1UpkoIJVp_Xx8oEiZj9ZZEY10').then(res => res.json()).then(json => {
      // //fetch('https://www.googleapis.com/youtube/v3/videos/?part=snippet&id=Ks-_Mh1QhMc%2Cc0KYU2j0TM4%2CeIho2S0ZahI&key=AIzaSyADO6BXZB1UpkoIJVp_Xx8oEiZj9ZZEY10').then(res => res.json()).then(json => {
      //   this.setState({YtData: json});
      //   //console.log(json);
      // });
    }
// https://www.googleapis.com/youtube/v3/playlistItems?part=snippet%2CcontentDetails&maxResults=25&playlistId=PLMRYn3pnaTARbxt6MUR8gr42Mhtey8rBJ&key=AIzaSyADO6BXZB1UpkoIJVp_Xx8oEiZj9ZZEY10


    render() {

      const opts = {
        height: '410px',
        width: '100%',
        playerVars: { // https://developers.google.com/youtube/player_parameters
          autoplay: 0,
          controls: 0,
          showinfo: 0,
          modestbranding: 0
        }
      };

      const listItems = this.state.YtData.items.map((item, index) =>

      <div className="videoList" key={index}>
          <h3>{item.snippet.title}</h3>
          <p>{item.snippet.description.substring(0, 200)} ...</p>
        <YouTube videoId={item.id} opts={opts} onReady={this._onReady} onPlay={this._onPlay} onPause={this._onStop} onEnd={this._onStop}/>
        </div>
    );

      return (

        <div className="contentMargin">

          <div className="section bg3">
            <div className="contentSize">
              <div className="content">
                {listItems}
              </div>
            </div>
          </div>

          <Footer />

        </div>
      );
    }

  }

  export default Videos;
