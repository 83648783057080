import React, { Component } from "react";

import YouTube from "react-youtube";
import Slider from "react-slick";
import Footer from "./Footer";
import NewsItems from "./NewsItems";
import { HashLink as Link } from "react-router-hash-link";
import PicoImg from "./img/photos/pico.png";
import Wig from "./img/photos/wig.png";
import Plasma from "./img/photos/plasma.png";

class Mma extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileSafari: {},
      YtData: {
        items: [],
      },
    };
  }

  componentDidMount() {
    fetch(
      "https://www.googleapis.com/youtube/v3/playlistItems?part=snippet%2CcontentDetails&maxResults=6&playlistId=PLZU-EOGC1MbOinYfVNv7qXO9GzWSI2IYO&key=AIzaSyADO6BXZB1UpkoIJVp_Xx8oEiZj9ZZEY10"
    )
      .then((res) => res.json())
      .then((json) => {
        this.setState({ YtData: json });
        //console.log(json);
      });

    var ua = window.navigator.userAgent;
    var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    var webkit = !!ua.match(/WebKit/i);
    var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

    if (iOSSafari) {
      this.setState({
        mobileSafari: {
          "background-attachment": "scroll",
          position: "fixed",
        },
      });
    }
  }

  render() {
    const opts = {
      height: "100%",
      width: "100%",
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 0,
        controls: 0,
        showinfo: 0,
        modestbranding: 0,
      },
    };

    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
    };

    const listYoutube = this.state.YtData.items.map((item, index) => (
      <div className="ytV" key={index}>
        <YouTube
          videoId={item.snippet.resourceId.videoId}
          opts={opts}
          onReady={this._onReady}
          onPlay={this._onPlay}
          onPause={this._onStop}
          onEnd={this._onStop}
        />
      </div>
    ));

    return (
      <div className="contentMargin">
        <div className="section">
          <div className="contentSize">
            <div className="content">
              <h2>MMA svářečky</h2>
              <p>
                Svářečky s obalenou elektrodou dostanou vždy nejvíc zabrat.
                Svařování v bahně, stísněných prostorách, výškách. Přenosné a
                přitom robustní stroje vás nezradí a to ani v téměř nemožných
                podmínkách.
              </p>
            </div>
          </div>
        </div>

        {/*<div className="introImg"></div>*/}

        <div className="productTable">
          <div className="product" to="/mma">
            <div className="indexCatImg pico3"></div>
          </div>
          <div className="product" to="/migmag">
            <ul className="subcategoryList">
              <li>Robustní a ideálně vhodný k použití na stavbách</li>
              <li>
                Velká tolerance síťového napětí od -40 % do +15 % pro
                bezproblémový provoz s generátory a až 100 m dlouhými síťovými
                přívody
              </li>
              <li>Chráněno proti stříkající vodě dle IP 23</li>
            </ul>
          </div>
        </div>
        <div className="productTable bgWhite">
          <div className="product " to="/migmag">
            <ul className="subcategoryList">
              <li>Optimálně vybavené pro všechny požadavky MMA</li>
              <li>
                Automatika zapalování pro bezpečné a šetrné zapalování brání
                přilepení elektrody k obrobku
              </li>
              <li>
                Stabilní svařovací oblouk se všemi typy elektrod, bez odtržení
                svařovacího oblouku
              </li>
              <li>
                Absolutně bezpečné svařování shora dolů celulózovými elektrodami
              </li>
              <li>
                MMA pulsování: Méně dodatečné práce díky minimalizované tvorbě
                rozstřiku, jednoduché svařování ve vynucených poloháchpři
                vynikajícím přemostění mezer
              </li>
            </ul>
          </div>
          <div className="product" to="/mma">
            <div className="indexCatImg pico4"></div>
          </div>
        </div>
        <div className="productTable">
          <div className="product" to="/mma">
            <div className="indexCatImg zaruka"></div>
          </div>
          <div className="product" to="/migmag">
            <ul className="subcategoryList">
              <li>S nepředstižitelnou zárukou kvality EWM</li>
              <li>
                3letá záruka na svařovací přístroje a 5letá záruka na
                transformátory a usměrňovače
              </li>
              <li>
                Bez omezení provozních hodin – dokonce i v 3směnném provozu, 24
                hodin denně, 7 dnů v týdnu
              </li>
            </ul>
          </div>
        </div>
        <div className="LineSmall">
          <div className="LineBgPico" style={this.state.mobileSafari}></div>
        </div>
        <div className="section soldamatic">
          <div className="contentSize">
            <div className="content">
              <h2>Pico 160 cel plus</h2>
              <p>
                Snadné ruční svařování obalenou elektrodou. Všude použitelný.
              </p>
              {/* 
              <div className="linkRect" to="/soldamatic">
                <span>Více o Soldamaticu</span>
              </div> */}
            </div>
          </div>
        </div>
        <div className="productTable bgWhite">
          <div className="product" to="/mma">
            <div className="indexCatImgBig pico5"></div>
          </div>
          <div className="product " to="/migmag">
            <ul className="subcategoryList">
              <li>Optimálně vybavené pro všechny požadavky MMA</li>
              <li>
                Automatika zapalování pro bezpečné a šetrné zapalování brání
                přilepení elektrody k obrobku
              </li>
              <li>
                Stabilní svařovací oblouk se všemi typy elektrod, bez odtržení
                svařovacího oblouku
              </li>
              <li>
                Absolutně bezpečné svařování shora dolů celulózovými elektrodami
              </li>
              <li>
                MMA pulsování: Méně dodatečné práce díky minimalizované tvorbě
                rozstřiku, jednoduché svařování ve vynucených poloháchpři
                vynikajícím přemostění mezer
              </li>
            </ul>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default Mma;
