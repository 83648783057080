import React, {Component} from 'react';

import YouTube from 'react-youtube';
import Slider from 'react-slick';
import Footer from './Footer';
import NewsItems from './NewsItems';
import {HashLink as Link} from 'react-router-hash-link';

class Privacy extends Component {

  constructor(props) {
    super(props);
    this.state = {
      'mobileSafari': {},
      YtData: {
        items: []
      }
    };
  }

  componentDidMount() {
    fetch('https://www.googleapis.com/youtube/v3/playlistItems?part=snippet%2CcontentDetails&maxResults=6&playlistId=PLZU-EOGC1MbOinYfVNv7qXO9GzWSI2IYO&key=AIzaSyADO6BXZB1UpkoIJVp_Xx8oEiZj9ZZEY10').then(res => res.json()).then(json => {
      this.setState({YtData: json});
      //console.log(json);
    });

    var ua = window.navigator.userAgent;
    var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    var webkit = !!ua.match(/WebKit/i);
    var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

    if (iOSSafari) {
      this.setState({
        'mobileSafari': {
          "background-attachment": "scroll",
          position: "fixed"
        }
      });
    }
  }

  render() {

    const opts = {
      height: '100%',
      width: '100%',
      playerVars: { // https://developers.google.com/youtube/player_parameters
        autoplay: 0,
        controls: 0,
        showinfo: 0,
        modestbranding: 0
      }
    };

    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true
    };

    const listYoutube = this.state.YtData.items.map((item, index) => <div className="ytV" key={index}><YouTube videoId={item.snippet.resourceId.videoId} opts={opts} onReady={this._onReady} onPlay={this._onPlay} onPause={this._onStop} onEnd={this._onStop}/></div>);

    return (<div className="contentMargin">

      <div className="section privacy">
        <div className="contentSize">
          <div className="content">
            <h1>ZÁSADY ZPRACOVÁNÍ OSOBNÍCH ÚDAJŮ</h1>
<p>
    Děkujeme za návštěvu našich webových stránek. Ochrana osobních údajů je pro
    nás velmi důležitá, proto Vás prostřednictvím těchto Zásad informujeme o
tom, jaké Vaše osobní údaje budeme na webu    <a href="http://www.wearewelding.cz">www.wearewelding.cz</a> zpracovávat,
    za jakým účelem, po jakou dobu a jak můžete ve vztahu k ochraně Vašich
    osobních údajů uplatnit svá práva.
</p>
<p>
    <strong>Správcem</strong>
    Vašich osobních údajů je společnost
    <strong>
        {" "}EWM HIGHTEC WELDING s.r.o., se sídlem 9. května 718, Jiříkov, 40753
    </strong>
    , IČ 62241001, zapsaná v obchodním rejstříku vedeném Krajským soudem v Ústí
    nad Labem, spisová značka C 7994 (dále jen <strong>„Společnost“</strong>).
    Společnost tímto prohlašuje, že osobní údaje zpracovává v souladu se
    zákonem č. 101/2000 Sb., o ochraně osobních údajů a o změně některých
    zákonů, ve znění pozdějších předpisů, resp. obecným nařízením o ochraně
    osobních údajů (nařízení Evropského parlamentu a Rady (EU) 2016/679).
</p>
<p>
    <strong>
        Pokud si naše stránky jen prohlížíte, neshromažďujeme žádné osobní
        údaje{" "}
    </strong>
    s výjimkou údajů poskytnutých Vaším internetovým prohlížečem nebo mobilním
    zařízením našemu internetovému serveru (tzv. „cookies“). Tyto technické
    údaje jsou nezbytné pro správné zobrazení webové stránky ve Vašem
    prohlížeči nebo mobilním zařízení. Využívání cookies je možné zakázat v
    nastavení Vašeho prohlížeče, může to však mít vliv na správnost zobrazení
    nebo fungování některých funkcí.
</p>
<p>
    <strong>
        K vyhodnocení a sestavování přehledů o aktivitě na našich webových
        stránkách{" "}
    </strong>
    využíváme službu Google Analytics, kterou provozuje společnost Google Inc.,
    se sídlem Amphitheatre Parkway, Mountain View, CA 94043, USA. Před předáním
    údajů této společnosti osobní údaje striktně anonymizujeme a této
    společnosti za účelem analýzy předáváme již jen zkrácené IP adresy (dle
    Dohody o Evropském hospodářském prostoru), na jejichž základě není možná
    identifikace konkrétních osob. Sběru cookies a analýze Google Analytics
    můžete zabránit například změnou nastavení svého prohlížeče nebo instalací
    speciálního pluginu do svého prohlížeče.
</p>
<p>
    Dále upozorňujeme, že na
    <strong>
        některé podstránky našich webových stránek vkládáme videa z Youtube{" "}
    </strong>
    . Odpovídající pluginy jsou provozovány společností YouTube, LLC, 901
    Cherry Ave., San Bruno, CA 94066, USA a Youtube tak může být informován,
    které stránky prohlížíte. Pokud jste přihlášeni k účtu Youtube, může
    přiřadit prohlížení Vaší osobě a personalizovat budoucí nabídku dle Vašeho
    nastavení účtu Youtube. Tomu lze zabránit tím, že se před navštívením
    našich stránek z účtu Youtube odhlásíte.
</p>
<p>
    Osobní údaje jsou informace, které slouží k identifikaci konkrétní fyzické
    osoby. <strong>Společnost</strong>
    <strong>
        od Vás může získat Vaše osobní údaje prostřednictvím kontaktního
        formuláře, emailu, či telefonického rozhovoru
    </strong>
    . Jedná se zejména o:
</p>
<ul>
    <li>
        jméno a příjmení
    </li>
    <li>
        e-mailovou adresu
    </li>
    <li>
        telefonní číslo
    </li>
    <li>
        další údaje poskytnuté vyplněním kontaktního formuláře
    </li>
</ul>
<p>
    Osobní údaje, které od vás získáme výše uvedenými prostředky, můžeme
    zpracovávat pro účely plnění smlouvy, plnění zákonných povinností nebo na
    základě oprávněného zájmu, který spočívá v kontaktování vaší osoby a
    navázání případné obchodní spolupráce.
</p>
<p>
    Vaše osobní údaje budeme zpracovávat pouze po dobu nezbytně nutnou k
    naplnění výše uvedeného účelu, pro který byly shromážděny – plnění smlouvy,
    plnění zákonných povinností nebo na základě oprávněného zájmu, který
    spočívá v kontaktování vaší osoby a navázání případné obchodní spolupráce.
    Tyto potřeby se pro různé typy údajů v kontextu různých produktů a služeb
    mohou lišit, a proto se může lišit také skutečná doba uchovávání informací.
    Mezi kritéria, na jejichž základě je určena doba uchovávání informací,
    patří:
</p>
<ul>
    <li>
        Jak dlouho jsou osobní údaje zapotřebí k poskytování produktů/služeb a
        pro zajištění chodu naší společnosti? To zahrnuje aktivity, jako je
        udržování a zlepšování výkonu těchto produktů/služeb, udržování
        zabezpečení našich systémů a udržování příslušných obchodních a
        finančních záznamů. Toto je obecně platné pravidlo, které je ve většině
        případů základem pro určení doby uchovávání dat.
    </li>
</ul>
<ul>
    <li>
        Zavedli jsme a oznámili jsme konkrétní dobu uchovávání pro určitý typ
        dat? Pokud ano, tak ji rozhodně nikdy nepřekročíme.
    </li>
</ul>
<ul>
    <li>
        Vztahují se na nás právní, smluvní nebo podobné závazky uchovávat data?
        Mezi příklady patří zákony upravující povinné uchovávání dat, vládní
        nařízení uchovávat data související s vyšetřováním nebo data, která je
        nutné uchovávat pro účely soudního sporu.
    </li>
</ul>
<p>
    <strong>Osobní údaje</strong>
    , které nám sdělíte výše uvedenými prostředky a vyslovíte souhlas s užitím
    pro zasílání obchodních sdělení, <strong>využijeme</strong> v rámci
obchodního oddělení Společnosti    <strong>pouze za účelem nabídky produktů a služeb Společnosti</strong>.
    Pokud nedojde k navázání obchodní spolupráce, či uzavření kupní smlouvy a
nezmění se tak účel zpracování osobních údajů, budeme Vaše osobní údaje    <strong>uchovávat do odvolání souhlasu, nejdéle však po dobu 3 let </strong>
    od obdržení Vašeho souhlasu. Po uplynutí této lhůty budou Vaše osobní údaje
    vymazány.
</p>
<p>
V souvislosti se zpracováním Vašich osobních údajů Společností<strong> máte </strong> jakožto subjekt údajů    <strong>následující práva</strong>, která můžete kdykoliv uplatnit:
</p>
<ul>
    <li>
        <strong>Právo na přístup </strong>
        znamená, že si kdykoliv můžete požádat o potvrzení, zda Společnost Vaše
        osobní údaje zpracovává, a pokud ano, pak za jakými účely, v jakém
        rozsahu, komu jsou zpřístupněny, jak dlouho je budeme zpracovávat. Také
        máte právo získat kopii Vašich osobních údajů, přičemž první poskytnutí
        je bezplatné, za další poskytnutí pak můžeme požadovat přiměřenou
        úhradu administrativních nákladů ve výši 100 Kč.
    </li>
</ul>
<ul>
    <li>
        <strong>Právo na opravu </strong>
        znamená, že nás kdykoliv můžete požádat o opravu či doplnění Vašich
        osobních údajů, pokud by byly nepřesné či neúplné.
    </li>
</ul>
<p>
    <strong></strong>
    <br/>
</p>
<ul>
    <li>
        <strong>Právo na výmaz </strong>
        znamená, že na Vaši žádost musíme vymazat Vaše osobní údaje pokud (i)
        již nejsou potřebné pro účely, pro které byly shromážděny nebo jinak
        zpracovány, (ii)odvoláte svůj souhlas se zpracováním svých osobních
        údajů a zároveň neexistují žádné další právní důvody pro jejich
        zpracování, (iii) vznesete námitky proti zpracování a neexistují žádné
        převažující oprávněné důvody pro zpracování, (iv) zpracování je
        protiprávní nebo (v) ukládá nám to zákonná povinnost.
    </li>
</ul>
<p>
    <strong></strong>
    <br/>
</p>
<ul>
    <li>
        <strong>Právo na omezení zpracování </strong>
        znamená, že dokud nevyřešíme jakékoliv sporné otázky ohledně zpracování
        Vašich osobních údajů, nesmíme Vaše osobní údaje zpracovávat jinak než
        tak, že je budeme mít pouze uloženy.
    </li>
</ul>
<p>
    <strong></strong>
    <br/>
</p>
<ul>
    <li>
        <strong>Právo vznést námitku </strong>
        znamená, že můžete vznést námitku proti zpracování Vašich osobních
        údajů, které zpracováváme z důvodu oprávněného zájmu.
    </li>
</ul>
<p>
    <strong></strong>
    <br/>
</p>
<ul>
    <li>
        <strong>Právo na přenositelnost </strong>
        znamená, že máte právo získat osobní údaje, které se Vás týkají, a
        které jsou zpracovávány automatizovaně a na základě souhlasu nebo
        smlouvy, ve strukturovaném, běžně používaném a strojově čitelném
        formátu, a právo na to, aby byly tyto osobní údaje předány přímo jinému
        správci.
    </li>
</ul>
<ul>
    <li>
        <strong>Právo odvolat souhlas </strong>
        znamená, že máte právo kdykoliv odvolat svůj souhlas s užitím pro
        zasílání obchodních sdělení. Po odvolání souhlasu již nebudou vaše
        osobní údaje pro daný účel nadále zpracovány. Odvoláním souhlasu není
        dotčena zákonnost zpracování vycházejícího ze souhlasu, který byl dán
        před jeho odvoláním.
    </li>
</ul>
<p>
    Pokud budete chtít <strong>uplatnit</strong> některé z Vašich výše
    uvedených práv, <strong>kontaktujte nás </strong>prosím pomocí naší
    speciálně zřízené e-mailové adresy gdpr@ewm-group.com.<strong> </strong>Na
    vaše dotazy či připomínky odpovíme do jednoho měsíce.
</p>
<p>
    Na naši činnost taktéž dohlíží Úřad pro ochranu osobních údajů, u kterého
    můžete v případě Vaší nespokojenosti podat stížnost. Více se dozvíte na
    internetových stránkách úřadu (<a href="http://www.uoou.cz">www.uoou.cz</a>
    ).
</p>
<p>
    Tyto Zásady zpracování osobních údajů jsou účinné od 25. 5. 2018 a mohou
    být dle potřeby aktualizovány.
</p>
        </div>
      </div>
    </div>

    <Footer/>

  </div>);
  }

}

export default Privacy;
