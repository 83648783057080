import { Component } from 'react';
import { withRouter } from 'react-router-dom';

class ScrollToTop extends Component {


  componentDidMount() {
          window.scrollTo(0, this.props.store.getState())
        //  console.log(this.props.store.getState());

  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
        window.scrollTo(0, this.props.store.getState())
    }
  }

  render() {
    return this.props.children
  }
}

export default ScrollToTop
