import React, {Component} from 'react';

import YouTube from 'react-youtube';
import Slider from 'react-slick';
import Footer from './Footer';
import NewsItems from './NewsItems';
import { HashLink as Link } from 'react-router-hash-link';


class Soldamatic extends Component {

  constructor(props) {
    super(props);
    this.state = {
      'mobileSafari': {},
      YtData: {
        items: []
      }
    };
  }

  componentDidMount() {
    fetch('https://www.googleapis.com/youtube/v3/playlistItems?part=snippet%2CcontentDetails&maxResults=6&playlistId=PLZU-EOGC1MbOinYfVNv7qXO9GzWSI2IYO&key=AIzaSyADO6BXZB1UpkoIJVp_Xx8oEiZj9ZZEY10').then(res => res.json()).then(json => {
      this.setState({YtData: json});
      //console.log(json);
    });

    var ua = window.navigator.userAgent;
    var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    var webkit = !!ua.match(/WebKit/i);
    var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

    if (iOSSafari) {
      this.setState({
        'mobileSafari': {
          "background-attachment": "scroll",
          position: "fixed"
        }
      });
    }
  }

  render() {

    const opts = {
      height: '100%',
      width: '100%',
      playerVars: { // https://developers.google.com/youtube/player_parameters
        autoplay: 0,
        controls: 0,
        showinfo: 0,
        modestbranding: 0
      }
    };

    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true
    };

    const listYoutube = this.state.YtData.items.map((item, index) => <div className="ytV" key={index}><YouTube videoId={item.snippet.resourceId.videoId} opts={opts} onReady={this._onReady} onPlay={this._onPlay} onPause={this._onStop} onEnd={this._onStop}/></div>);

    return (<div className="contentMargin soldamatic">

      <div className="section soldamatic">
        <div className="contentSize">
          <div className="content">
            <h2>Soldamatic</h2>
            <p>První trenažér svařování v umělé realitě</p>

          </div>
        </div>
      </div>
      <div className="soldamaticImg"></div>
      <div className="section fourProducts">
        <div className="contentSize">

          <div className="contentHeading">
            <h2>Rychlé učení, šetrné na materiál</h2>
            <p>Realistický trenažér svařování s přilbou osazenou brýlemi pro virtuální realitu. Výsledkem je autentická zkušenost žáka. Propojení s monitorovaným průběhem umožňuje přesné měření učebního pokroku. </p>
          </div>

          <div className="content">
            <h2>Názorná výuka</h2>
            <p>Soldomatic umožňuje živé i zpětné přehrávání průběhu jednotlivých svařování. Žáci tak mohou přesně vidět, kde udělali chyby, a jak je zlepšit. </p>
          </div>

          <div className="content">
            <h2>Měření výsledků</h2>
            <p>Senzory měří každý pohyb svářeče v reálném čase. Výsledkem jsou přesné statistiky času, průběhu a kvality svaru. </p>
          </div>
          <div className="content">
            <h2>Úspora nákladů</h2>
            <p>Při učení žák nespotřebovává žádný materiál. Výukou na Soldomaticu šetříte finance stejně jako planetu. </p>

          </div>
          <div className="content">
            <h2>Software pro instruktory</h2>
            <p>Školitel má k dispozici software, ve kterém má záznamy všech žáků, jejich pokroků a prospěchu. Vše v přehledném prostředí. </p>

          </div>
        </div>
      </div>


      <div className="section">
        <div className="contentSize">

          <div className="content">
            <h2>Podívejte se na video</h2>
            <div className="ytV videoTopMargin"><YouTube videoId={"mFO74WWoKbw"} opts={opts} onReady={this._onReady} onPlay={this._onPlay} onPause={this._onStop} onEnd={this._onStop}/></div>
          </div>

        </div>
      </div>

      <div className="section center threeProducts">
        <div className="contentSize">
          <div className="contentHeading">
            <h2>Pro koho je Soldamatic určen</h2>
          </div>

          <div className="content">
            <h3>Odborné školy</h3>
          </div>

          <div className="content">
            <h3>Průmyslové společnosti</h3>
          </div>
          <div className="content">
            <h3>Rekvalifikační centra</h3>

          </div>

        </div>
      </div>

      <div className="section newsIndex">
        <div className="contentSize">
          <div className="content">
            <h2>Inspirujte se od mistrů</h2>
            <div className="newsIndexWrapper">
              <NewsItems from={0} to={3}/>
              <Link className="linkRect" to="/news"><span>Podívejte se na další články</span></Link>
            </div>
          </div>
        </div>
      </div>

      <Footer/>

    </div>);
  }

}

export default Soldamatic;
