import React, {Component} from 'react';
import Footer from './Footer';
//import {Link} from 'react-router-dom';

  class Reference extends Component {

    componentDidMount() {

    }

    render() {


      return (

        <div className="contentMargin">

          <div className="section bg1">
            <div className="contentSize">
              <div className="content">
                <h2>Hoj</h2>
                </div>
            </div>
          </div>





          <Footer />

        </div>
      );
    }

  }

  export default Reference;
