import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Settings from './lib/settings';

class NewsItems extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: {
        news: []
      }
    };
  }

  componentDidMount() {
    fetch('/api/news', Settings.fetchParams('GET')).then(res => res.json()).then(json => {
      if (json !== null) {
        this.setState({
          data: {
            news: json.news
          }
        });
      }
    });
  }

  render() {


    const listItems = this.state.data.news.slice(this.props.from, this.props.to).map((item, index) => {

      let linkToArticle = <br/>;
      let Comp = <div className="newsTextWrapper" key={index}>
        <h3>{item.title}</h3>
        <div className={this.props.newsColor ? "newsImage newsImageColor" : "newsImage"} style={{backgroundImage: "url(./img/news/thumb_" + item.image + ")"}}></div>
        <p className="newsText">{item.short_text}</p>
      </div>;

      if(item.slug !== null) {
        linkToArticle = <Link to={"/article/" + item.slug} className="newsLink">Celý článek</Link>;
          Comp = <Link to={"/article/" + item.slug} className="newsTextWrapper" key={index}>

            <h3>{item.title}</h3>
            <div className={this.props.newsColor ? "newsImage newsImageColor" : "newsImage"} style={{backgroundImage: "url(./img/news/thumb_" + item.image + ")"}}></div>
            <p className="newsText">{item.short_text}</p>

          </Link>
      }

    return Comp}
  );

    return (<div className="news">

      {listItems}
      <div className="clear"></div>
    </div>);
  }

}

export default NewsItems;
