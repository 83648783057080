import React, {Component} from 'react';
import Footer from './Footer';

  class Article extends Component {

    constructor(props) {
      super(props);
      this.state = {
        data: {
          content: ""
        }
      };
      this.loadPage(this.props);
      }

      loadPage(myProps) {

        fetch('/api/article/' + myProps.match.params.slug).then(res => res.json()).then(json => {
          this.setState({data: JSON.parse(json)});

        });
      }


    render() {


      let d = new Date(this.state.data.date);
      const content = this.state.data.text;
      function createMarkup() {
        return {__html: content};
      };

      return (

        <div className="contentMargin">

          <div className="section bg3">
            <div className="contentSize">
              <div className="content">

                <div className="newsBig">
                  <div className="newsTextWrapperBig">
                    <h3 className="newsBigH3">{this.state.data.title}</h3>
                    <img src={"../../img/news/" + this.state.data.image } className="articleImg" alt={this.state.data.title}/>
                    <p className="newsDate">{d.getUTCDate() + "." + d.getUTCMonth() + ". " + d.getUTCFullYear()}</p>

                    <div dangerouslySetInnerHTML={createMarkup()}></div>
                  </div>
                </div>


              </div>
            </div>
          </div>

          <Footer />

        </div>
      );
    }

  }

  export default Article;
