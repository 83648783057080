import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import Home from "./Home";
import News from "./News";
import Videos from "./Videos";
import Article from "./Article";
import Contact from "./Contact";
import Reference from "./Reference";
import ScrollToTop from "./ScrollToTop";
import Mma from "./Mma";
import Migmag from "./Migmag";
import Tig from "./Tig";
import Plasma from "./Plasma";
import Technology from "./Technology";
import Soldamatic from "./Soldamatic";
import Privacy from "./Privacy";
import Doosan from "./Doosan";
import EwmDay from "./EwmDay";

//import About from './pages/About';
//import Page from './pages/Page';import ScrollToTop from './ScrollToTop';
//import { spring, AnimatedSwitch } from 'react-router-transition';

/*function mapStyles(styles) {
  return {
    opacity: styles.opacity,
    transform: `scale(${styles.scale})`,
  };
}

// wrap the `spring` helper to use a bouncy config
function bounce(val) {
  return spring(val, {
    stiffness: 330,
    damping: 22,
  });
}

// child matches will...
const bounceTransition = {
  // start in a transparent, upscaled state
  atEnter: {
    opacity: 0,
    scale: 1.2,
  },
  // leave in a transparent, downscaled state
  atLeave: {
    opacity: bounce(0),
    scale: bounce(0.8),
  },
  // and rest at an opaque, normally-scaled state
  atActive: {
    opacity: bounce(1),
    scale: bounce(1),
  },
};
*/
class Main extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={Home} />
        <ScrollToTop
          resChange={this.props.resChange}
          store={this.props.store}
          refParalax={this.props.refParalax}
        >
          <Switch>
            <Route exact path="/news" component={News} />
            <Route path="/article/:slug" component={Article} />
            <Route exact path="/mma" component={Mma} />
            <Route exact path="/migmag" component={Migmag} />
            <Route exact path="/tig" component={Tig} />
            <Route exact path="/plasma" component={Plasma} />
            <Route exact path="/videos" component={Videos} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/reference" component={Reference} />
            <Route exact path="/technology" component={Technology} />
            <Route exact path="/soldamatic" component={Soldamatic} />
            <Route exact path="/privacy" component={Privacy} />
            <Route exact path="/doosan_robotics" component={Doosan} />
            <Route exact path="/ewm_day" component={EwmDay} />
            <Route component={Home} />
          </Switch>
        </ScrollToTop>

        {/*    <ScrollToTop  resChange={this.props.resChange} store={this.props.store}>

      <Route exact path='/about' component={About} />
      <Route path='/page/:page' component={Page} />

    </ScrollToTop>*/}
      </Switch>
    );
  }
}

export default Main;
