import React, { Component } from "react";

import YouTube from "react-youtube";
import Slider from "react-slick";
import Footer from "./Footer";
import NewsItems from "./NewsItems";
import { HashLink as Link } from "react-router-hash-link";
import TetrixPlasma from "./img/photos/plasma/tetrixplasma.png";
import MicroPlasma from "./img/photos/plasma/microplasma.png";

class Plasma extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileSafari: {},
      YtData: {
        items: [],
      },
    };
  }

  componentDidMount() {
    fetch(
      "https://www.googleapis.com/youtube/v3/playlistItems?part=snippet%2CcontentDetails&maxResults=6&playlistId=PLZU-EOGC1MbOinYfVNv7qXO9GzWSI2IYO&key=AIzaSyADO6BXZB1UpkoIJVp_Xx8oEiZj9ZZEY10"
    )
      .then((res) => res.json())
      .then((json) => {
        this.setState({ YtData: json });
        //console.log(json);
      });

    var ua = window.navigator.userAgent;
    var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    var webkit = !!ua.match(/WebKit/i);
    var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

    if (iOSSafari) {
      this.setState({
        mobileSafari: {
          "background-attachment": "scroll",
          position: "fixed",
        },
      });
    }
  }

  render() {
    const opts = {
      height: "100%",
      width: "100%",
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 0,
        controls: 0,
        showinfo: 0,
        modestbranding: 0,
      },
    };

    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
    };

    const listYoutube = this.state.YtData.items.map((item, index) => (
      <div className="ytV" key={index}>
        <YouTube
          videoId={item.snippet.resourceId.videoId}
          opts={opts}
          onReady={this._onReady}
          onPlay={this._onPlay}
          onPause={this._onStop}
          onEnd={this._onStop}
        />
      </div>
    ));

    return (
      <div className="contentMargin">
        <div className="section">
          <div className="contentSize">
            <div className="content">
              <h2>Plazmové svářečky</h2>
              <p>
                <strong>Dokonalé automaty</strong>
              </p>
              <p>
                Zdroje vhodné spíše pro automatizované svařování, opravy a
                navařování strojních komponentů. Svařovací oblouk je typický
                vysokou hustotou energie a minimálním rozptylem. To zajišťuje
                směrovou stálost svařovacího oblouku a minimální vnesené teplo.{" "}
              </p>
              <p>
                Vyberte plazmu pokud potřebujete miniaturní sváry a nejvyšší
                kvalitu zpracování.
              </p>
            </div>
          </div>
        </div>

        <div className="productTable bgWhite">
          <div className="product " to="/migmag">
            <ul className="subcategoryList">
              <li>Technologie s koncentrovaným plasmovým obloukem</li>
              <li>Mezistupeň mezi metodou TIG a laserovým svařováním</li>
              <li>
                Vhodné zejména na extrémně tenké materiály, nebo tam kde chceme
                minimalizovat vnesené teplo do okolí sváru
              </li>
              <li>Jemné a stabilní nastavení proudu po 0,1 A </li>
              <li>Moderní ovládací panel comfort 2.0</li>
              <li>Precizní pulsní proces</li>
              <li>Funkce bodového svařování/stehování (spotArc/Spotmatic)</li>
              <li>
                vysoká rychlost svařování s minimalizovaným pronikáním tepla
                působením svázaného svařovacího oblouku{" "}
              </li>
            </ul>
          </div>
          <div className="product" to="/mma">
            <div className="indexCatImg pico4"></div>
          </div>
        </div>
        <div className="productTable">
          <div className="product" to="/mma">
            <div className="indexCatImg zaruka"></div>
          </div>
          <div className="product" to="/migmag">
            <ul className="subcategoryList">
              <li>S nepředstižitelnou zárukou kvality EWM</li>
              <li>
                3letá záruka na svařovací přístroje a 5letá záruka na
                transformátory a usměrňovače
              </li>
              <li>
                Bez omezení provozních hodin – dokonce i v 3směnném provozu, 24
                hodin denně, 7 dnů v týdnu
              </li>
            </ul>
          </div>
        </div>

        {/* <div className="section twoProducts">
        <div className="contentSize">
          <div className="content">
            <h2>TetrixPlasma</h2>
            <div className="productImage">
              <img src={TetrixPlasma} alt="TetrixPlasma" className="productImage"/></div>

          </div>
          <div className="content">
            <h2>MicroPlasma</h2>
            <div className="productImage">
              <img src={MicroPlasma} alt="MicroPlasma" className="productImage"/></div>

          </div>
        </div>
      </div>

      <div className="section">
        <div className="contentSize">
          <div className="content">
            <h2>Použité technologie</h2>
            <div className="usedTechnologyWrapper">
              <div className="usedTechnology" to="/technology#activearc">activeArc</div>
            </div>

          </div>
        </div>
      </div>
      <div className="Line">
        <div className="LineBg1" style={this.state.mobileSafari}></div>
      </div>

      <div className="section newsIndex">
        <div className="contentSize">
          <div className="content">
            <h2>Inspirujte se od mistrů</h2>
            <div className="newsIndexWrapper">
              <NewsItems from={0} to={3}/>
              <div className="linkRect" to="/news"><span>Podívejte se na další články</span></div>
            </div>
          </div>
        </div>
      </div> */}

        <Footer />
      </div>
    );
  }
}

export default Plasma;
