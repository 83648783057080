import React, { Component } from "react";

import YouTube from "react-youtube";
import Slider from "react-slick";
import Footer from "./Footer";
import NewsItems from "./NewsItems";
import { HashLink as Link } from "react-router-hash-link";
import picomig180puls from "./img/photos/cat/picomig180puls.jpg";
import phoenix355puls from "./img/photos/cat/phoenix355puls.jpg";
import titan from "./img/photos/cat/titan.jpg";
import MigViedo from "./video/ewm.mp4";
import Migmag from "./img/index/MigMag.jpg";

class Mma extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileSafari: {},
      YtData: {
        items: [],
      },
    };
  }

  componentDidMount() {
    fetch(
      "https://www.googleapis.com/youtube/v3/playlistItems?part=snippet%2CcontentDetails&maxResults=6&playlistId=PLZU-EOGC1MbOinYfVNv7qXO9GzWSI2IYO&key=AIzaSyADO6BXZB1UpkoIJVp_Xx8oEiZj9ZZEY10"
    )
      .then((res) => res.json())
      .then((json) => {
        this.setState({ YtData: json });
        //console.log(json);
      });

    var ua = window.navigator.userAgent;
    var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    var webkit = !!ua.match(/WebKit/i);
    var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

    if (iOSSafari) {
      this.setState({
        mobileSafari: {
          "background-attachment": "scroll",
          position: "fixed",
        },
      });
    }
  }

  render() {
    const opts = {
      height: "100%",
      width: "100%",
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 0,
        controls: 0,
        showinfo: 0,
        modestbranding: 0,
      },
    };

    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
    };

    const listYoutube = this.state.YtData.items.map((item, index) => (
      <div className="ytV" key={index}>
        <YouTube
          videoId={item.snippet.resourceId.videoId}
          opts={opts}
          onReady={this._onReady}
          onPlay={this._onPlay}
          onPause={this._onStop}
          onEnd={this._onStop}
        />
      </div>
    ));

    return (
      <div className="contentMargin">
        <div className="section">
          <div className="contentSize">
            <div className="content">
              <h2>MIG/MAG svářecí zdroje</h2>
              <p>
                <strong>Univerzál na dlouhé trati</strong>
              </p>
              <p>
                MIG/MAG od EWM svařuje od nelegovaných, nízkolegovaných ocelí a
                CrNi až po meď a hliník. Podávají konstantní výkon po celou
                směnu. Společně se nezastavíte před ničím.
              </p>
            </div>
          </div>
        </div>

        <div className="section intro">
          <video loop muted autoPlay playsInline className="introVideo">
            <source src={MigViedo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="productTable">
          <div className="product" to="/mma">
            <div
              className="indexCatImgContain"
              style={{ backgroundImage: `url(${picomig180puls})` }}
            ></div>
          </div>
          <div className="product" to="/migmag">
            <h1>Řada Picomig</h1>
            <h2>Synergic/Puls</h2>
            <ul className="subcategoryList">
              <li>Kompaktní a robustní společník do terénu</li>
              <li>Synergické a pulsní křivky pro všechny typy materiálu</li>
              <li>4 kladkový podavač</li>
              <li>Snadné a spolehlivé ovládání</li>
              <li>Optimální pro svařování plněnými dráty s vlastní ochranou</li>
              <li>Dodat brožuru</li>
            </ul>
          </div>
        </div>
        <div className="productTable bgWhite">
          <div className="product " to="/migmag">
            <h1>Řada Taurus/Phoenix</h1>
            <h2>Synergic/Puls</h2>
            <ul className="subcategoryList">
              <li>Bestseller ewm</li>
              <li>Programovou výbavou předčí konkurenci - bez příplatku</li>
              <li>Díky vysokým zatěžovatelům zvládne opravdu těžkou výrobu</li>
              <li>Dokonale připraven na svařování hliníku a nerezi</li>
              <li>
                Přehledný a intuitivní ovládací panel navržen dle požadavků
                samotných svářečů
              </li>
              <li>Jednoduchá změna polarity svařování</li>
              <li>
                Chlazené plynem nebo volitelně vodní chlazení s chladicím
                modulem
              </li>
              <li>Úspora elektrické energie díky vysokému stupni účinnost</li>
              <li>
                synergické charakteristiky EWM forceArc, EWM forceArc puls, EWM
                rootArc, EWM rootArc puls a superPuls{" "}
              </li>
            </ul>
          </div>
          <div className="product" to="/mma">
            <div
              className="indexCatImgContain "
              style={{ backgroundImage: `url(${phoenix355puls})` }}
            ></div>
          </div>
        </div>
        <div className="productTable">
          <div className="product" to="/mma">
            <div
              className="indexCatImgContain "
              style={{ backgroundImage: `url(${titan})`, height: "550px" }}
            ></div>
          </div>
          <div className="product" to="/migmag">
            <h1>Řada Titan XQ Puls</h1>
            <ul className="subcategoryList">
              <li>Vlajková loď ewm</li>
              <li>Intuitivní možnosti ovládání</li>
              <li>Revoluce ve svařování hliníku MIG-AC</li>
              <li>Ergonomický design a bezpečná manipulace</li>
              <li>
                Vylepšené vlastnosti svařování ve všech procesech svařování
              </li>
              <li>
                Dokonalé svary MIG/MAG i u dlouhých svařovacích vedení díky
                patentované* technologii Rapid Current Control (RCC)
              </li>
              <li>Informační panel LED: Signalizuje aktuální provozní stav</li>
              <li>
                Vysoce přesný, silný 4kladkový pohon posuvu drátu EWM eFeed k
                bezpečnému posuvu všech plných a plněných drátů
              </li>
              <li>
                S inovativními synergickými charakteristikami EWM rootArc® XQ /
                rootArc® puls XQ, coldArc® XQ / coldArc® puls XQ, forceArc® XQ /
                forceArc® puls XQ, wiredArc XQ / wiredArc puls XQ, Impuls /
                superPuls a Positionweld
              </li>
              <li>
                Volitelně lze propojit prostřednictvím LAN nebo brány WiFi se
                softwarem Xnet ewm
              </li>
            </ul>
            <a className="brozura" href="/files/TITANXQCZ.pdf" download>
              <i className="fas fa-file-pdf"></i> Brožura
            </a>
          </div>
        </div>

        <div className="productTable bgWhite">
          <div className="product " to="/migmag">
            <ul className="subcategoryList ">
              <li>S nepředstižitelnou zárukou kvality EWM</li>
              <li>
                3letá záruka na svařovací přístroje a 5letá záruka na
                transformátory a usměrňovače
              </li>
              <li>
                Bez omezení provozních hodin – dokonce i v 3směnném provozu, 24
                hodin denně, 7 dnů v týdnu
              </li>
            </ul>
          </div>
          <div className="product" to="/mma">
            <div className="indexCatImg zaruka"></div>
          </div>
        </div>
        <div className="productTable">
          <div className="product" to="/mma">
            <div
              className="indexCatImgContain "
              style={{
                backgroundImage: `url(${Migmag})`,
                backgroundSize: "cover",
              }}
            ></div>
          </div>
          <div className="product" to="/migmag">
            <ul className="subcategoryList">
              <li>Dokonalý doplněk ke svařování hliníku</li>
              <li>Osvětlení LED v rukojeti spínané pouze pohybem</li>
              <li>Vynikající odvádění tepla v těle hořáku</li>
              <li>
                Grafický displej a ukazatel funkcí k zapnutí procesů svařovaní
                přímo z pracoviště
              </li>
            </ul>
          </div>
        </div>
        {/* <div className="section fiveProducts aHoverZoom">
          <div className="contentSize">
            <a
              className="content taurusColumn"
              href="https://www.ewm-sales.com/cs/Svareci_pristroje_MIG_MAG/Taurus_Synergic_S___Taurus_Basic--13854.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="">
                <h2>Taurus</h2>
                <p>Synergické svařování s profesionální výbavou</p>
                <div className="productImage">
                  <img src={Taurus} alt="Taurus" />
                </div>
              </div>
            </a>
            <a
              className="content"
              href="https://www.ewm-sales.com/cs/Svareci_pristroje_MIG_MAG/Phoenix_puls--13853.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="">
                <h2>Phoenix</h2>
                <p>Pulsní invertory pro každou aplikaci</p>
                <div className="productImage">
                  <img src={Phoenix} alt="Phoenix" />
                </div>
              </div>
            </a>
            <a
              className="content titanColumn"
              href="https://www.ewm-sales.com/cs/Svareci_pristroje_MIG_MAG/Titan_XQ_puls--13773.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="">
                <h2>Titan</h2>
                <p>
                  Vlajková loď rodiny
                  <br />
                  ewm
                </p>
                <div className="productImage">
                  <img src={Titan} alt="Titan" />
                </div>
              </div>
            </a>

            <a
              className="content"
              href="https://www.ewm-sales.com/cs/Svareci_pristroje_MIG_MAG/Picomig_Synergic___Picomig_puls--13856.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="">
                <h2>picoMig</h2>
                <p>
                  Invertory pro jednoduché aplikace a montáže - s pulsem i bez
                </p>
                <div className="productImage">
                  <img src={Picomig} alt="Pico" />
                </div>
              </div>
            </a>
            <a
              className="content wegaColumn"
              href="https://www.ewm-sales.com/cs/Svareci_pristroje_MIG_MAG/Postupne_prepinane_svareci_pristroje--7069.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="">
                <h2>Wega</h2>
                <p>
                  Klasické svařovací zdroje pro tvrdou práci se stupňovitou
                  regulací
                </p>
                <div className="productImage">
                  <img src={Switching} alt="Pico" />
                </div>
              </div>
            </a>
          </div>
        </div> */}

        <Footer />
      </div>
    );
  }
}

export default Mma;
