import React, {Component} from 'react';

import YouTube from 'react-youtube';
import Scroll from 'react-scroll';
import Slider from 'react-slick';
import Footer from './Footer';
import NewsItems from './NewsItems';
import { HashLink as Link } from 'react-router-hash-link';
import TetrixPlasma from './img/photos/plasma/tetrixplasma.png';
import MicroPlasma from './img/photos/plasma/microplasma.png';


class Technology extends Component {

  constructor(props) {
    super(props);
    this.state = {
      'mobileSafari': {},
      YtData: {
        items: []
      }
    };
  }

  componentDidMount() {
    fetch('https://www.googleapis.com/youtube/v3/playlistItems?part=snippet%2CcontentDetails&maxResults=6&playlistId=PLZU-EOGC1MbOinYfVNv7qXO9GzWSI2IYO&key=AIzaSyADO6BXZB1UpkoIJVp_Xx8oEiZj9ZZEY10').then(res => res.json()).then(json => {
      this.setState({YtData: json});
      //console.log(json);
    });

    var ua = window.navigator.userAgent;
    var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    var webkit = !!ua.match(/WebKit/i);
    var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

    if (iOSSafari) {
      this.setState({
        'mobileSafari': {
          "background-attachment": "scroll",
          position: "fixed"
        }
      });
    }
  }

  render() {

    const opts = {
      height: '100%',
      width: '100%',
      playerVars: { // https://developers.google.com/youtube/player_parameters
        autoplay: 0,
        controls: 0,
        showinfo: 0,
        modestbranding: 0
      }
    };

    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true
    };

    const listYoutube = this.state.YtData.items.map((item, index) => <div className="ytV" key={index}><YouTube videoId={item.snippet.resourceId.videoId} opts={opts} onReady={this._onReady} onPlay={this._onPlay} onPause={this._onStop} onEnd={this._onStop}/></div>);

    return (<div className="contentMargin technology">

      <div className="section">
        <div className="contentSize">
          <div className="content">
            <h2>Technologie, které mění svařování</h2>
            <p className="center">Vývojem nových svařovacích technologií se v EWM zabýváme již více než padesát let. Za tu dobu jsme vytvořili celou řadu patentů a nových postupů. Postupy a technologie přichází a zase odchází. </p>
            <p className="center">Při vývoji sledujeme dva hlavní cíle - <strong>zjednodušení práce svářečů a ušetření provozních nákladů.</strong> A ty se v čase nemění. </p>
            <p className="center">Podívejte se na technologie, které nás udržují na světové špičce mezi svářecími zdroji. </p>
              <div className="usedTechnologyWrapper">
                <Link smooth className="usedTechnologyPage" to="/technology#forcearcplus">forceArc plus</Link>
                <Link smooth className="usedTechnologyPage" to="/technology#forcearc">forceArc</Link>
                <Link smooth className="usedTechnologyPage" to="/technology#activearc">coldArc</Link>
              </div>
          </div>
        </div>
      </div>

      <div className="section" id="forcearcplus">
        <div className="contentSize">
          <div className="content">
            <h2>forceArc puls</h2>
            <p className="center"><strong>Rychlé a hospodárné pulzní svařování bez rozstřiku</strong></p>
            <p className="center">Metoda svařování MIG/MAG impulsním obloukem s minimalizovanou teplotou. Snadné použití a v celém výkonovém pásmu vhodné ke svařování nelegovaných, nízko a vysokolegovaných materiálů.</p>
            <p className="center">Vyznačuje se vynikajícím přemostěním mezery, dokonce i ve vysokém výkonovém pásmu a umožňuje kontrolované svařování při proměnlivé vzduchové mezeře až 4 mm.</p>
            <h3>Výhody:</h3>
            <p><strong>Vyšší rychlost svařování</strong></p>
            <ul>
              <li>Symetrické tvoření svarů a maximálně možná tloušťka svaru (rozměr a) u koutových svarů</li>
              <li>Vynikající smáčení</li>
              <li>Hluboký, koncentrovaný závar s bezpečným provařením kořene</li>
            </ul>

            <p><strong>Téměř bez rozstřiku</strong></p>
            <ul>
              <li>Minimalizace dodatečné práce</li>
              <li>Také u plechů s okujeným nebo silně znečištěným povrchem</li>
            </ul>

            <p><strong>Zdravá práce</strong></p>
            <ul>
              <li>Nízké zatěžování svářeče na pracovišti</li>
              <li>Méně svařovacího kouře</li>
              <li>Tichý, příjemný zvuk svařovacího oblouku</li>
              <li>Snadné vedení (vynikající smáčivost)</li>
            </ul>

          </div>
        </div>
      </div>

      <div className="section" id="forcearc">
        <div className="contentSize">
          <div className="content">
            <h2>forceArc</h2>
            <p className="center">Úsporné hluboké svařování v horním výkonnostním pásmu</p>
            <h3>Výhody:</h3>
            <p><strong>Hluboký závar</strong></p>
            <ul>
              <li>Redukce rozměru</li>
              <li>Zúžení průřezu</li>
            </ul>

            <p><strong>Rychlá změna délky drátu</strong></p>
            <ul>
              <li>Perfektní svařování i s velmi dlouhými konci drátu</li>
            </ul>

            <p><strong>Ideální pro úzké spáry a koutové sváry</strong></p>
            <ul>
              <li>Úzký úhel (30° a 40°) otevření svaru</li>
            </ul>

            <p><strong>Rapidní zrychlení procesu</strong></p>
            <ul>
              <li>Díky nízkému objemu svaru</li>
              <li>Až o 50% v porovnání se standardním sprchovým obloukem</li>
            </ul>
            <Link className="linkRect" to="/migmag">
              <span>Přejít na svářečky MIG/MAG</span>
            </Link>
          </div>
        </div>
      </div>

      <div className="section" id="activearc">
        <div className="contentSize">
          <div className="content">
            <h2>activeArc</h2>
            <p className="center"><strong>Dynamický svařovací oblouk WIG s kompenzovaným výkonem </strong></p>
            <p className="center">activArc umožňuje dynamicky měnit energii při změnách vzdálenosti od obrobku. Tím předchází nežadoucím změnám energie při oddalování a přibližování hořáku, jak je to obvyklé při klasickém WIG svařování.</p>
            <h3>Výhody:</h3>
            <p><strong>Vysoká úspora nákladů</strong></p>
            <ul>
              <li>Rychlejší svařování díky soustředěnému svařovacímu oblouku</li>
              <li>Méně dodatečných prací</li>
              <li>Snížením počtu náběhových barev u obrobku</li>
              <li>Lepší provaření kořene</li>
              <li>Hluboký závar</li>
              <li>Koncentrované pronikání tepla</li>
            </ul>

            <p><strong>Vysoce kvalitní výsledek svařování</strong></p>
            <ul>
              <li>Minimalizace chyb při stehování – zabránění vměstkům wolframu</li>
              <li>Nižší deformace materiálu</li>
              <li>Lepší stabilita svařovacího oblouku WIG zvláště u nízkých proudů</li>
              <li>Vlastnosti activArc nastavitelné pro každou tloušťku materiálu</li>
            </ul>

            <Link className="linkRect" to="/tig">
              <span>Přejít na svářečky TIG</span>
            </Link>

          </div>
        </div>
      </div>
      <div className="Line">
        <div className="LineBg1" style={this.state.mobileSafari}></div>
      </div>

      <div className="section newsIndex">
        <div className="contentSize">
          <div className="content">
            <h2>Inspirujte se od mistrů</h2>
            <div className="newsIndexWrapper">
              <NewsItems from={0} to={3}/>
              <Link className="linkRect" to="/news"><span>Podívejte se na další články</span></Link>
            </div>
          </div>
        </div>
      </div>

      <Footer/>

    </div>);
  }

}

export default Technology;
