import React, { Component } from "react";

import Footer from "./Footer";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

class EwmDay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      YtData: {
        items: [],
      },
    };
  }

  componentDidMount() {
    fetch(
      "https://www.googleapis.com/youtube/v3/playlistItems?part=snippet%2CcontentDetails&maxResults=6&playlistId=PLZU-EOGC1MbOinYfVNv7qXO9GzWSI2IYO&key=AIzaSyADO6BXZB1UpkoIJVp_Xx8oEiZj9ZZEY10"
    )
      .then((res) => res.json())
      .then((json) => {
        this.setState({ YtData: json });
        //console.log(json);
      });
  }

  render() {
    var ua = window.navigator.userAgent;
    var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    var webkit = !!ua.match(/WebKit/i);
    var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

    const MyMapComponentOstrava = withScriptjs(
      withGoogleMap((props) => (
        <GoogleMap
          defaultZoom={17}
          defaultCenter={{ lat: 49.82620910971927, lng: 18.252918294508785 }}
        >
          {props.isMarkerShown && (
            <Marker
              position={{ lat: 49.82620910971927, lng: 18.252918294508785 }}
            />
          )}
        </GoogleMap>
      ))
    );
    return (
      <div className="contentMargin">
        <div className="section">
          <div className="contentSize">
            <div className="content">
              <h2>
                Setkání obchodních partnerů ewm 11.6&nbsp;-&nbsp;12.6.&nbsp;2024
              </h2>
              <p>
                Setkání ochodních partnerů ewm a odborníků zabývajících se
                aplikacemi z oblasti svařování, se zaměřením na stávající
                trendy, novinky v oblasti svařování a automatizace. Cílem je
                upevnění pozice na trhu a rozvoj do budoucna.
              </p>
              <p>
                Prosíme i o potvrzení účasti do 17.5.&nbsp;2024 a to včetně
                počtu zúčastněných a požadavku na parkovací místa na emailovou
                adresu nebo v kontaktním formuláři níže.
              </p>
            </div>
          </div>
        </div>

        {!iOSSafari && (
          <div className="LineSmall">
            <div className="LineBgDnyEwm"></div>
          </div>
        )}

        <div className="section">
          <div className="contentSize">
            <div className="content">
              <h2>Co můžete očekávat?</h2>
              <p>
                • Představení novinek v sortimentu ewm
                <br />
                • Automatizační řešení
                <br />
                • Orbitální svařování
                <br />
                • Svařovací workshop
                <br />
                • Kolaborativní roboti
                <br />
                • Systém sběru svařovacích dat Xnet
                <br />
                ... a mnoho dalšího ...
                {/* <ul>
                  <li>Představení novinek v sortimentu EWM</li>
                  <li>Automatizační řešení</li>
                  <li>Orbitální svařování</li>
                  <li>Svařovací workshop</li>
                  <li>Kolaborativní roboti</li>
                  <li>Systém sběru svařovacích dat Xnet</li>
                  <li>a mnoho dalšího…</li>
                </ul> */}
              </p>
            </div>
          </div>
        </div>

        <div className="productTable bgWhite noBottomPadding">
          <div className="product">
            <div className=" ">
              <h2>Kde a kdy?</h2>
              <p>
                <b>11.6. od 10:00</b> na adrese Tovární 11, Ostrava – Mariánské
                hory, 70900
                <br /> Svou účast prosím potvrďte ve formuláři níže, případně na
                email. <br />
                <br />
                <b>Těšíme se na Vás, Tým ewm</b>
              </p>

              <div className="mapEwmDay">
                <MyMapComponentOstrava
                  isMarkerShown
                  googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyCk16CnO0Iddc5jxFxqGRR4ZCa72_zcMHY&libraries=geometry,drawing,places"
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: `200px` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                />
              </div>
            </div>
          </div>
          <div className="product">
            <div className=" ">
              <h2>
                Zajištěno je pro vás pohoštění formou cattering a společenská
                večeře.
              </h2>
            </div>
            <div className=" ">
              <div className="indexCatImgSmall catering"></div>
            </div>
          </div>
        </div>
        <div className="productTable bgWhite noTopPadding">
          <div className="product">
            <div className=" ">
              <h2>
                Společně vám můžeme zajistit rezervaci v hotelu Harmony club
              </h2>
              <p>Nahlaste prosím počet účastníků i požadavek na ubytování. </p>
              <p>Budeme Vás dále informovat.</p>
            </div>
          </div>
          <div className="product">
            <div className=" ">
              <div className="indexCatImg harmony"></div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default EwmDay;
