import React, { Component } from "react";

import YouTube from "react-youtube";
import Slider from "react-slick";
import Footer from "./Footer";
import NewsItems from "./NewsItems";
import { HashLink as Link } from "react-router-hash-link";
import PicoImg from "./img/photos/pico.png";
import Wig from "./img/photos/wig.png";
import Plasma from "./img/photos/plasma.png";
import RobotViedo from "./video/robot.mp4";

class Doosan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileSafari: {},
      YtData: {
        items: [],
      },
    };
  }

  componentDidMount() {
    fetch(
      "https://www.googleapis.com/youtube/v3/playlistItems?part=snippet%2CcontentDetails&maxResults=6&playlistId=PLZU-EOGC1MbOinYfVNv7qXO9GzWSI2IYO&key=AIzaSyADO6BXZB1UpkoIJVp_Xx8oEiZj9ZZEY10"
    )
      .then((res) => res.json())
      .then((json) => {
        this.setState({ YtData: json });
        //console.log(json);
      });

    var ua = window.navigator.userAgent;
    var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    var webkit = !!ua.match(/WebKit/i);
    var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

    if (iOSSafari) {
      this.setState({
        mobileSafari: {
          "background-attachment": "scroll",
          position: "fixed",
        },
      });
    }
  }

  render() {
    const opts = {
      height: "100%",
      width: "100%",
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 0,
        controls: 0,
        showinfo: 0,
        modestbranding: 0,
      },
    };

    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
    };

    const listYoutube = this.state.YtData.items.map((item, index) => (
      <div className="ytV" key={index}>
        <YouTube
          videoId={item.snippet.resourceId.videoId}
          opts={opts}
          onReady={this._onReady}
          onPlay={this._onPlay}
          onPause={this._onStop}
          onEnd={this._onStop}
        />
      </div>
    ));

    return (
      <div className="contentMargin">
        <div className="section">
          <div className="contentSize">
            <div className="content">
              <h2>Doosan Robotics</h2>
              <p>
                Spolupracující roboty nejvyšší kvality. To je Doosan Robotics.
                Využijte jednoduchost, umožňující jejich zavedení do výroby v
                řádu hodin!
              </p>
            </div>
          </div>
        </div>

        {/*<div className="introImg"></div>*/}

        <div className="productTable">
          <Link className="product" to="/mma">
            <div className="indexCatImg doosan"></div>
          </Link>
          <Link className="product" to="/migmag">
            <ul className="subcategoryList">
              <li>
                Uživatelsky přívětivé programovací prostředí pro velmi rychlou
                orientaci a tvorbu programu za pomoci intuitivních nástrojů.
              </li>
              <li>
                Po krátkém zaškolení zvládne personál progarmovat složité pohyby
                bez znalosti programovacího jazyka
              </li>
            </ul>
          </Link>
        </div>
        <div className="productTable bgWhite">
          <Link className="product " to="/migmag">
            <ul className="subcategoryList">
              <li>
                Prvek Cockpit umožnuje snadné nastavení robota do požadované
                polohy
              </li>
              <li>
                Snadné nastavení robota do požadované polohy obouma rukama bez
                nutnosti zmáčknout tlačítko bezpečnosti
              </li>
              <li>Jednoduché ukládání polohy stiskem tlačítka</li>
              <li>Velké množství definovaných pohybů</li>
              <li>Stav robota při programování signalizuje LED pásek</li>
            </ul>
          </Link>
          <Link className="product" to="/mma">
            <div className="indexCatImg doosan3"></div>
          </Link>
        </div>
        <div className="productTable">
          <Link className="product" to="/mma">
            <div className="indexCatImg doosanB"></div>
          </Link>
          <Link className="product" to="/migmag">
            <ul className="subcategoryList">
              <li>Bezpečnost Doosan Robotics je na nejvyšší úrovni</li>
              <li>
                Kolizní senzitivita na osách robota je vyladěna k dokonalosti
              </li>
              <li>
                Možnost nastavení pro až 20 bezpečnostních zón různých tvarů
              </li>
            </ul>
          </Link>
        </div>
        <div className="section intro">
          <video loop muted autoPlay playsInline className="introVideo">
            <source src={RobotViedo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="section bgWhite paddingTopContent">
          <div className="contentSize">
            <div className="contentSmallerPadding">
              <h2>M - SERIES</h2>
              <p>Senzitivita a dosah</p>
            </div>
          </div>
        </div>
        <div className="productTable productTableNoTop bgWhite">
          <Link className="product" to="/mma">
            <div className="indexCatImg2 doosanM"></div>
          </Link>
          <Link className="product " to="/migmag">
            <ul className="subcategoryList">
              <li>Nosnost: 6–15 kg</li>
              <li>Dosah: 900–1700 mm</li>
              <li>Silový senzor: na každé ose (6 celkem)</li>
            </ul>
          </Link>
        </div>

        <div className="section bgWhite">
          <div className="contentSize">
            <div className="contentSmallerPadding">
              <h2>A - SERIES</h2>
              <p>Rychlost</p>
            </div>
          </div>
        </div>
        <div className="productTable productTableNoTop bgWhite">
          <Link className="product" to="/mma">
            <div className="indexCatImg2 doosanA"></div>
          </Link>
          <Link className="product " to="/migmag">
            <ul className="subcategoryList">
              <li>Nosnost: 5–9 kg</li>
              <li>Dosah: 900–1200 mm</li>
              <li>Silový senzor: variantně na šesté ose</li>
            </ul>
          </Link>
        </div>

        <div className="section bgWhite">
          <div className="contentSize">
            <div className="contentSmallerPadding">
              <h2>H - SERIES</h2>
              <p>Dosah a nosnost</p>
            </div>
          </div>
        </div>
        <div className="productTable productTableNoTop bgWhite">
          <Link className="product" to="/mma">
            <div className="indexCatImg2 doosanM"></div>
          </Link>
          <Link className="product " to="/migmag">
            <ul className="subcategoryList">
              <li>Nosnost: 20–25 kg</li>
              <li>Dosah: 1500–1700 mm</li>
              <li>Silový senzor: na každé ose (6 celkem)</li>
            </ul>
          </Link>
        </div>

        <Footer />
      </div>
    );
  }
}

export default Doosan;
