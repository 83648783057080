import React, { Component } from "react";
import ReactDOM from "react-dom";
import { HashLink as Link } from "react-router-hash-link";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formName: "",
      formOrg: "",
      formMail: "",
      formTel: "",
      formText: "",
      formNewsletter: true,
      sended: false,
      formPrivacy: false,
      sKlik: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState({
      [event.target.name]: value,
    });
    event.target.style.removeProperty("background-color");
    event.target.style.removeProperty("border-color");
  }

  handleSubmit(event) {
    event.preventDefault();
    if (!this.state.sended) {
      function validateEmail(email) {
        var re =
          /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      }

      function validateTel(tel) {
        var re = /^([\s]*[+]{1}[0-9?\s]{9,20}[\s]*)$/;
        return re.test(String(tel));
      }

      var errorCheck = true;

      if (
        this.state.formName === "" ||
        this.state.formOrg === "" ||
        this.state.formTel === "" ||
        this.state.formMail === "" ||
        this.state.formText === ""
      ) {
        alert("Pro odeslání prosím vyplňte všechna pole!");
        errorCheck = false;
        ReactDOM.findDOMNode(this.refs.parallaxSmoke);

        if (this.state.formPrivacy === false) {
          ReactDOM.findDOMNode(this.refs.privacy).style.backgroundColor =
            "#ffe5e3";
          ReactDOM.findDOMNode(this.refs.privacy).style.borderColor = "red";
        }

        if (this.state.formName === "") {
          ReactDOM.findDOMNode(this.refs.name).style.backgroundColor =
            "#ffe5e3";
          ReactDOM.findDOMNode(this.refs.name).style.borderColor = "red";
        }

        if (this.state.formOrg === "") {
          ReactDOM.findDOMNode(this.refs.org).style.backgroundColor = "#ffe5e3";
          ReactDOM.findDOMNode(this.refs.org).style.borderColor = "red";
        }

        if (this.state.formTel === "") {
          ReactDOM.findDOMNode(this.refs.tel).style.backgroundColor = "#ffe5e3";
          ReactDOM.findDOMNode(this.refs.tel).style.borderColor = "red";
        }

        if (this.state.formMail === "") {
          ReactDOM.findDOMNode(this.refs.mail).style.backgroundColor =
            "#ffe5e3";
          ReactDOM.findDOMNode(this.refs.mail).style.borderColor = "red";
        }

        if (this.state.formText === "") {
          ReactDOM.findDOMNode(this.refs.text).style.backgroundColor =
            "#ffe5e3";
          ReactDOM.findDOMNode(this.refs.text).style.borderColor = "red";
        }
      }

      if (this.state.formPrivacy === false) {
        alert("Pro odeslání musíte souhlasit se zpracovnáním osobních údajů!");
        ReactDOM.findDOMNode(this.refs.privacy).style.backgroundColor =
          "#ffe5e3";
        ReactDOM.findDOMNode(this.refs.privacy).style.borderColor = "red";
        errorCheck = false;
      }

      if (!validateEmail(this.state.formMail) && errorCheck) {
        alert("Pro odeslání prosím vyplňte validní email!");
        ReactDOM.findDOMNode(this.refs.mail).style.backgroundColor = "#ffe5e3";
        ReactDOM.findDOMNode(this.refs.mail).style.borderColor = "red";
        errorCheck = false;
      }

      if (!validateTel(this.state.formTel) && errorCheck) {
        alert(
          "Pro odeslání prosím vyplňte telefon ve formátu +yyy xxx xxx xxx"
        );
        ReactDOM.findDOMNode(this.refs.tel).style.backgroundColor = "#ffe5e3";
        ReactDOM.findDOMNode(this.refs.tel).style.borderColor = "red";
        errorCheck = false;
      }

      if (errorCheck) {
        //eactDOM.findDOMNode(this.refs.submit).className = "submiting";
        ReactDOM.findDOMNode(this.refs.submit).innerHTML =
          '<i class="fas fa-spinner spin"></i> Odesílám';

        window.gtag("event", "conversion", {
          send_to: "AW-760677418/RjaHCNWc3ZUBEKqI3OoC",
        });
        this.setState({ sKlik: true });

        fetch("/api/contact", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            formName: this.state.formName,
            formOrg: this.state.formOrg,
            formMail: this.state.formMail,
            formTel: this.state.formTel,
            formText: this.state.formText,
            formNewsletter: this.state.formNewsletter,
          }),
        })
          .then((res) => {
            if (res.ok) {
              return res.json;
            } else {
              ReactDOM.findDOMNode(this.refs.submit).className = "submitError";
              ReactDOM.findDOMNode(this.refs.submit).innerHTML =
                '<i class="fas fa-exclamation-triangle"></i> Error';
            }
          })
          .then((json) => {
            if (json) {
              ReactDOM.findDOMNode(this.refs.submit).className = "submitOk";
              ReactDOM.findDOMNode(this.refs.submit).innerHTML =
                '<i class="fas fa-check"></i> Odesláno';
              this.setState({ sended: true });
            } else {
              ReactDOM.findDOMNode(this.refs.submit).className = "submitError";
              ReactDOM.findDOMNode(this.refs.submit).innerHTML =
                '<i class="fas fa-exclamation-triangle"></i> Error';
            }
          });
      }
    } else {
      alert("Již odesláno!");
    }
  }

  render() {
    const MyMapComponentBenesov = withScriptjs(
      withGoogleMap((props) => (
        <GoogleMap
          defaultZoom={17}
          defaultCenter={{ lat: 49.78089, lng: 14.684562 }}
        >
          {props.isMarkerShown && (
            <Marker position={{ lat: 49.78089, lng: 14.684562 }} />
          )}
        </GoogleMap>
      ))
    );

    const MyMapComponentOstrava = withScriptjs(
      withGoogleMap((props) => (
        <GoogleMap
          defaultZoom={17}
          defaultCenter={{ lat: 49.841509, lng: 18.308483 }}
        >
          {props.isMarkerShown && (
            <Marker position={{ lat: 49.841509, lng: 18.308483 }} />
          )}
        </GoogleMap>
      ))
    );

    return (
      <footer id="footer">
        <div id="ordershow">
          <div className="contentSize">
            <div className="content">
              <div className="contactForm">
                <h2 className="showFreeH">NAPIŠTE NÁM</h2>
                <p className="showFreeP">A my Vám se svařováním pomůžeme!</p>
                <form onSubmit={this.handleSubmit}>
                  <div className="contactRow">
                    <div className="contactForm50">
                      <label>Jméno</label>
                      <i className="fas fa-user inputIcon footerContactIcon"></i>
                      <input
                        type="text"
                        value={this.state.formName}
                        name="formName"
                        onChange={this.handleChange}
                        ref="name"
                      ></input>
                    </div>

                    <div className="contactForm50">
                      <label>Organizace</label>
                      <i className="fas fa-building inputIcon footerContactIcon"></i>
                      <input
                        type="text"
                        value={this.state.formOrg}
                        name="formOrg"
                        onChange={this.handleChange}
                        ref="org"
                      ></input>
                    </div>
                  </div>

                  <div className="contactRow">
                    <div className="contactForm50">
                      <label>E-mail</label>
                      <i className="fas fa-envelope inputIcon footerContactIcon"></i>
                      <input
                        type="text"
                        value={this.state.formMail}
                        name="formMail"
                        onChange={this.handleChange}
                        ref="mail"
                      ></input>
                    </div>

                    <div className="contactForm50">
                      <label>Telefon</label>
                      <i className="fas fa-phone inputIcon footerContactIcon"></i>
                      <input
                        type="text"
                        value={this.state.formTel}
                        name="formTel"
                        onChange={this.handleChange}
                        ref="tel"
                      ></input>
                    </div>
                  </div>

                  <div className="clear"></div>
                  <div className="contactForm100">
                    <label>
                      Prosím uveďte typ svářečky, datum a případné další
                      požadavky
                    </label>
                    <textarea
                      value={this.state.formText}
                      name="formText"
                      onChange={this.handleChange}
                      ref="text"
                    />
                  </div>
                  <div className="contactForm100">
                    <input
                      type="checkbox"
                      className="privacyCheckbox"
                      value={this.state.privacy}
                      name="formPrivacy"
                      onChange={this.handleChange}
                      ref="privacy"
                    />
                    <Link to="/privacy">
                      Seznámil jsem se se zásadami zpracování osobních údajů
                    </Link>
                  </div>
                  <div className="contactForm100 newsletterCheckbox">
                    <input
                      type="checkbox"
                      className="privacyCheckbox"
                      checked={this.state.formNewsletter}
                      name="formNewsletter"
                      onChange={this.handleChange}
                    />
                    <span>Souhlasím se zasíláním obchodních sdělení</span>
                  </div>
                  <button
                    type="submit"
                    ref="submit"
                    className="orderShowButton"
                  >
                    <i className="fas fa-share-square" ref="buttonIco"></i>{" "}
                    Objednat zdarma!
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
