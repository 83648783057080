import React, { Component } from "react";
import { HashLink as Link } from "react-router-hash-link";
import ReactDOM from "react-dom";
//import { withRouter } from "react-router";

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        menu: [
          { item: "Domů", slug: "/#home" },
          { item: "Články", slug: "/news" },
          { item: "Videa", slug: "/videos" },
          { item: "O nás", slug: "/contact#about" },
          { item: "Kontakt", slug: "/contact#contact" },
          { item: "Setkání obchodních partnerů", slug: "/ewm_day" },
        ],
        menuTech: [
          { item: "MMA", slug: "/news" },
          { item: "MIG/MAG", slug: "/videos" },
          { item: "TIG/WIG", slug: "/contact#about" },
          { item: "Plasma", slug: "/contact#contact" },
        ],
      },
      showMenu: {},
      windowWidth: window.innerWidth,
    };
    //, {item: "Reference", slug: "/reference"}
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize.bind(this));
  }

  handleResize() {
    if (window.innerWidth > 782) {
      this.setState({ showMenu: {} });
    }
  }

  _onMenuClick() {
    switch (this.state.showMenu.display) {
      case "block":
        this.setState({
          showMenu: {
            display: "none",
          },
        });

        break;
      default:
        this.setState({
          showMenu: {
            display: "block",
          },
        });
        if (window.pageYOffset < this.props.store.getState()) {
          window.scrollTo(0, this.props.store.getState());
        }
    }
  }
  _onMenuItemClick() {
    if (window.innerWidth < 1000) {
      this.setState({
        showMenu: {
          display: "none",
        },
      });
    }
  }

  showTechnologySubmenu = () => {
    ReactDOM.findDOMNode(this.refs.technologyMenu).style.display = "block";
  };

  render() {
    const listItems = this.state.data.menu.map((item, index) => (
      <div className="menuItemContainer" key={index}>
        <Link
          to={item.slug}
          key={index}
          onClick={this._onMenuItemClick.bind(this)}
        >
          {item.item}
        </Link>
      </div>
    ));
    const listItemsTechnology = this.state.data.menuTech.map((item, index) => (
      <div className="menuTechnologyItemContainer" key={index}>
        <Link
          to={item.slug}
          key={index}
          onClick={this._onMenuItemClick.bind(this)}
        >
          {item.item}
        </Link>
      </div>
    ));

    return (
      <div
        className={this.props.className}
        ref="menu"
        style={this.props.stMenuF}
      >
        <div className="parallax-logo-img" ref={this.props.logoRef}>
          <Link to="/#wearewelding" className="logoLink" />
        </div>
        <div
          className="menuMobileButton"
          onClick={this._onMenuClick.bind(this)}
        >
          <i className="fa fa-bars" aria-hidden="true"></i>
        </div>
        <div className="navbarItems" style={this.state.showMenu}>
          {/*<p className="technologyMenuButton"><span onClick={this.showTechnologySubmenu}>Technologie</span><div className="menuTechnologyContainer" ref="technologyMenu">
              {listItemsTechnology}
            </div></p>*/}
          {listItems}
        </div>
      </div>
    );
  }
}

export default Menu;
