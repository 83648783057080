import React, { Component } from "react";

import YouTube from "react-youtube";
import Slider from "react-slick";
import Footer from "./Footer";
import NewsItems from "./NewsItems";
import { HashLink as Link } from "react-router-hash-link";
import Tetrix from "./img/photos/wig/tetrix.png";
import Picotig from "./img/photos/wig/picotig.png";
import Tigspeed from "./img/photos/wig/tigspeed.png";
import Acdc from "./img/photos/wig/acdc.png";

class Tig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileSafari: {},
      YtData: {
        items: [],
      },
    };
  }

  componentDidMount() {
    fetch(
      "https://www.googleapis.com/youtube/v3/playlistItems?part=snippet%2CcontentDetails&maxResults=6&playlistId=PLZU-EOGC1MbOinYfVNv7qXO9GzWSI2IYO&key=AIzaSyADO6BXZB1UpkoIJVp_Xx8oEiZj9ZZEY10"
    )
      .then((res) => res.json())
      .then((json) => {
        this.setState({ YtData: json });
        //console.log(json);
      });

    var ua = window.navigator.userAgent;
    var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    var webkit = !!ua.match(/WebKit/i);
    var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

    if (iOSSafari) {
      this.setState({
        mobileSafari: {
          "background-attachment": "scroll",
          position: "fixed",
        },
      });
    }
  }

  render() {
    const opts = {
      height: "100%",
      width: "100%",
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 0,
        controls: 0,
        showinfo: 0,
        modestbranding: 0,
      },
    };

    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
    };

    return (
      <div className="contentMargin">
        <div className="section">
          <div className="contentSize">
            <div className="content">
              <h2>TIG/WIG svářečky</h2>
              <p>
                <strong>Profíci na jemnou práci</strong>
              </p>
              <p>
                TIG svářečky jsou určeny pro tu nejpřesnější práci na tenkém
                materiálu, ve vynucených polohách a v kořenových vrstvách.
                Docílíte s nimi nejpřesnějších a nejpevnější svárů.
              </p>
            </div>
          </div>
        </div>

        <div className="productTable">
          <div className="product" to="/mma">
            <div className="indexCatImg picotigB"></div>
          </div>
          <div className="product" to="/migmag">
            <h1>Řada Picotig</h1>
            <ul className="subcategoryList">
              <li>
                Jednoduché ovládání, ale přesto s nadstandartními funkcemi
              </li>
              <li>Ideální řešení na montáže</li>
              <li>Pulsní svařování jak pro TIG tak pro MMA</li>
              <li>
                Nastavitelný tvar proudu AC, sinusový, lichoběžníkový,
                obdélníkový{" "}
              </li>
              <li>
                Chlazené plynem nebo volitelně vodní chlazení s chladicím
                modulem{" "}
              </li>
              <li>
                Vysoké tolerance síťového napětí +15 %/-40 %, a tím neomezeně
                schopné pro generátorový provoz
              </li>
            </ul>
            <a className="brozura" href="/files/pico160cz.pdf" download>
              <i className="fas fa-file-pdf"></i> Brožura
            </a>
          </div>
        </div>
        <div className="productTable bgWhite">
          <div className="product " to="/migmag">
            <h1>Řada Tetrix</h1>
            <h2>DC AC/DC</h2>
            <ul className="subcategoryList">
              <li>Kompaktní, lehké, robustní</li>
              <li>Dokonale intuitivní ovládání</li>
              <li>Perfektní svařování DC pro CrNi, titan a mnohé další</li>
              <li>Flexibilní svařování AC pro hliník</li>
              <li>Smart nebo Comfort – vyberte řízení, které se vám hodí</li>
              <li>
                Spotmatic – ušetří až 50% pracovních nákladů při stěhování
              </li>
              <li>Nastavitelná frekvence střídavého proudu 50 Hz až 200 Hz</li>

              <li>
                Vysoké tolerance síťového napětí +15 %/-40 %, a tím neomezeně
                schopné pro generátorový provoz
              </li>
            </ul>
            <a className="brozura" href="/files/tetrix230300cz.pdf" download>
              <i className="fas fa-file-pdf"></i> Brožura
            </a>
          </div>
          <div className="product" to="/mma">
            <div className="indexCatImg tetrixAcdc"></div>
          </div>
        </div>
        <div className="productTable">
          <div className="product" to="/mma">
            <div className="indexCatImg tetrixP"></div>
          </div>
          <div className="product" to="/migmag">
            <h1>Řada Tetrix</h1>
            <h2>351 - 551</h2>
            <ul className="subcategoryList">
              <li>
                Svými výkony a zatěžovately se řadí mezi nejvýkonější stroje na
                trhu
              </li>
              <li>Téměř nezničitelné provedení do těžkého provozu</li>
              <li>
                Bez příplatku vybavené charakteristikami pro EWM activArc a EWM
                spotArc
              </li>
              <li>
                Nastavitelný tvar proudu AC, sinusový, lichoběžníkový,
                obdélníkový
              </li>
              <li>svařování obalenou elektrodou / drážkování </li>
              <li>Velké zálohy výkonu díky vysokému dovolenému zatížení </li>
              <li> vodní chlazení s velkou nádrží 12 litrů </li>
            </ul>
          </div>
        </div>
        <div className="productTable bgWhite">
          <div className="product " to="/migmag">
            <h1>Tigspeed</h1>
            <h2>&nbsp;</h2>
            <ul className="subcategoryList">
              <li>Samostatný podavač přídavného materiálu ke strojům Tetrix</li>
              <li>
                Režim kontinuálního podávání drátu nebo podávání drátu s
                oscilací
              </li>
              <li>
                Možnost podávání studeného nebo horkého drátu (coldwire/hotwire)
              </li>
              <li>
                Ideální řešení pro dlouhé sváry s vysokou postupovou rychlostí
              </li>
              <li>Řešení pro ruční i automatizované aplikace</li>
            </ul>
            <a className="brozura" href="/files/tigspeeden.pdf" download>
              <i className="fas fa-file-pdf"></i> Brožura
            </a>
          </div>
          <div className="product" to="/mma">
            <div className="indexCatImg tigspeed"></div>
          </div>
        </div>
        <div className="productTable">
          <div className="product" to="/mma">
            <div className="indexCatImg zaruka"></div>
          </div>
          <div className="product" to="/migmag">
            <ul className="subcategoryList">
              <li>S nepředstižitelnou zárukou kvality EWM</li>
              <li>
                3letá záruka na svařovací přístroje a 5letá záruka na
                transformátory a usměrňovače
              </li>
              <li>
                Bez omezení provozních hodin – dokonce i v 3směnném provozu, 24
                hodin denně, 7 dnů v týdnu
              </li>
            </ul>
          </div>
        </div>

        {/* <div className="section threeProducts aHoverZoom">
        <div className="contentSize">
          <a className="content" href="https://www.ewm-sales.com/cs/Svarovaci_pristroje_WIG/Svarecky_WIG_na_stejnosmerny_proud--7070.html"  target="_blank" rel="noopener noreferrer">
          <div className="">
            <h2>TIG DC</h2>
            <div className="productImage">
              <img src={Tetrix} alt="Tetrix" className="productImage"/></div>
                <p>Všestranné zdroje pro svařování stejnosměrným proudem. Pyšní se pulzním svařováním a nadstandardní výbavou. Široké výkonostní pole od 200A po 550A.</p>

          </div>
          </a>
          <a className="content" href="https://www.ewm-sales.com/cs/Svarovaci_pristroje_WIG/Tetrix_AC_DC--14175.html" target="_blank" rel="noopener noreferrer">
          <div className="">
            <h2>TIG AC/DC</h2>
            <div className="productImage">
              <img src={Acdc} alt="Tetrix ACDC" className="productImage"/></div>
                <p>Svařovací přístroje AC/DC od společnosti EWM jsou Vašim ideálním partnerem při svařování hliníku.</p>

          </div>
        </a>
        <a className="content" href="https://www.ewm-sales.com/cs/Soucasti_a_prislusenstvi/Podavace_dratu__spotrebni_dily_a_prislusenstvi/WIG--7538.html" target="_blank" rel="noopener noreferrer">
          <div className="">
            <h2>TigSpeed</h2>
            <div className="productImage">
              <img src={Tigspeed} alt="TigSpeed" className="productImage"/>

              </div>
                <p>Multifunkční podavač drátu pro zdroje Tetrix. Podává studený i horký drát. S oscilací nebo bez. Takto rychlé svařování metodou TIG jen tak nezažijete.</p>

          </div>
          </a>
        </div>
      </div>
      <div className="section">
        <div className="contentSize">
          <div className="content">
            <h2>Použité technologie</h2>
            <div className="usedTechnologyWrapper">
              <div className="usedTechnology" to="/technology#activearc">activeArc</div>
            </div>

          </div>
        </div>
      </div>
      <div className="Line">
        <div className="LineBg2" style={this.state.mobileSafari}></div>
      </div>

      <div className="section newsIndex">
        <div className="contentSize">
          <div className="content">
            <h2>Inspirujte se od mistrů</h2>
            <div className="newsIndexWrapper">
              <NewsItems from={0} to={3}/>
              <div className="linkRect" to="/news"><span>Podívejte se na další články</span></div>
            </div>
          </div>
        </div>
      </div> */}

        <Footer />
      </div>
    );
  }
}

export default Tig;
