import { Component } from "react";
import { Slide } from "react-slideshow-image";

import Wi from "./img/reference/wi.jpg";
import Tigtech from "./img/reference/tigtech.jpg";
import Astron from "./img/reference/astron.png";
import Vop from "./img/reference/vop.jpg";
import Evobus from "./img/reference/evobus.gif";

import WiFoto from "./img/reference/foto/wi.jpg";
import TigtechFoto from "./img/reference/foto/tigtech.jpg";
import AstronFoto from "./img/reference/foto/astron.jpg";
import VopFoto from "./img/reference/foto/vop.jpg";
import EvobusFoto from "./img/reference/foto/evobus.jpg";

class ReferenceSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Slide
          easing="ease"
          arrows={true}
          canSwipe={false}
          prevArrow={
            <div className="sliderArrowLeft">
              <i className="fas fa-arrow-circle-left "></i>
            </div>
          }
          nextArrow={
            <div className="sliderArrowRight">
              <i className="fas fa-arrow-circle-right"></i>
            </div>
          }
        >
          <div className="each-slide">
            <div
              className="referenceFoto"
              style={{ backgroundImage: `url(${EvobusFoto})` }}
            ></div>
            <div className="referenceText">
              <h2>EvoBus Česká republika s.r.o.</h2>
              <div
                className="referenceLogo"
                style={{ backgroundImage: `url(${Evobus})` }}
              ></div>
              <p>
                Přední evropský výrobce autobusů Mercedes-Benz 168 pulzních
                svařovacích zdrojů Phoenix
              </p>
              <p>
                <b>Jak charakterizujete zdroje ewm ve vaší společnosti?</b>
                <br />
                <i>
                  “V roce 2019 jsme realizovali 168 pulzních pracovišť, řada z
                  nich byla upravena výrobcem podle našich požadavků.“
                </i>
              </p>
            </div>
          </div>
          <div className="each-slide">
            <div
              className="referenceFoto"
              style={{ backgroundImage: `url(${VopFoto})` }}
            ></div>
            <div className="referenceText">
              <h2>VOP CZ, s.p.</h2>
              <div
                className="referenceLogo"
                style={{ backgroundImage: `url(${Vop})` }}
              ></div>
              <p>
                Výroba a renovace těžkých obrněných vozidel. Více než 50 ks
                svařovacích zdrojů řady Taurus a Phoenix
              </p>
              <p>
                <b>Jak charakterizujete zdroje ewm ve vaší společnosti?</b>
                <br />
                <i>
                  “Používáme zdroje v třísměnném provozu již řadu let, téměř
                  bezporuchový provoz, snadná obsluha.”
                </i>
              </p>
            </div>
          </div>
          <div className="each-slide">
            <div
              className="referenceFoto"
              style={{ backgroundImage: `url(${TigtechFoto})` }}
            ></div>
            <div className="referenceText">
              <h2>TigTech s.r.o.</h2>
              <div
                className="referenceLogo"
                style={{ backgroundImage: `url(${Tigtech})` }}
              ></div>
              <p>
                Specializace firmy je svařování slitin hliníku. Svařovací zdroje
                řady Phoenix, Titan a Tetrix AC/DC.
              </p>
              <p>
                <b>Jak charakterizujete zdroje ewm ve vaší společnosti?</b>
                <br />
                <i>
                  “Naše firma používá výhradně zdroje ewm, protože pro svařování
                  hliníků jsme zdroje této značky vyhodnotili jako nejlepší. Od
                  drobných výrobků až po masivní kusy.“
                </i>
              </p>
            </div>
          </div>
          <div className="each-slide">
            <div
              className="referenceFoto"
              style={{ backgroundImage: `url(${AstronFoto})` }}
            ></div>
            <div className="referenceText">
              <h2>Astron Buildings s.r.o.</h2>
              <div
                className="referenceLogo"
                style={{ backgroundImage: `url(${Astron})` }}
              ></div>
              <p>
                Výstavba průmyslových ocelových hal. Více než 20 ks svařovacích
                zdrojů řady Taurus a Phoenix.
              </p>
              <p>
                <b>Jak charakterizujete zdroje ewm ve vaší společnosti?</b>
                <br />
                <i>
                  “Realizovali jsme společně s ewm nové svařovací pracoviště pro
                  výrobu dlouhých svařenců, které přesně vyhovuje požadavkům pro
                  naši výrobu.“
                </i>
              </p>
            </div>
          </div>
          <div className="each-slide">
            <div
              className="referenceFoto"
              style={{ backgroundImage: `url(${WiFoto})` }}
            ></div>
            <div className="referenceText">
              <h2>Witzenmann Opava spol. s r.o.</h2>
              <div
                className="referenceLogo"
                style={{ backgroundImage: `url(${Wi})` }}
              ></div>
              <p>
                Výroba kovových hadic, kompenzátorů, kovových vlnovců, držáků
                potrubí a dílů pro všechny známé výrobce automobilů. Mikroplazmy
                ewm.
              </p>
              <p>
                <b>Jak charakterizujete zdroje ewm ve vaší společnosti?</b>
                <br />
                <i>
                  “Mikroplazmové zdroje máme nasazené na automatech při podélném
                  svařování trubek v téměř nepřetržitém provozu.“
                </i>
              </p>
            </div>
          </div>
        </Slide>
      </div>
    );
  }
}

export default ReferenceSlider;
